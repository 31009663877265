export default function setActiveStyleSheet(id) {
    const styleSheets = document.head.querySelectorAll('link[rel="stylesheet"]');
    Array.from(styleSheets).forEach(sheet => {
        if (sheet.getAttribute('href') === `${id}.css`) {
            sheet.disabled = false;
        }
        else if (!sheet.getAttribute('href').includes('google')) {
            sheet.disabled = true;
        }
        ;
    });
}
