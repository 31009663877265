import Modal from "./modalClass";
export default class AccessibilityModal extends Modal {
    constructor(modal, controlButton, focusOnDisplay, accessibility) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        super(modal, controlButton, focusOnDisplay);
        this.accessibility = accessibility;
        this.accessibilityList = (_a = this.node) === null || _a === void 0 ? void 0 : _a.querySelector('.modal-access_liste');
        this.switchLines = (_b = this.node) === null || _b === void 0 ? void 0 : _b.querySelectorAll('.switch');
        this.switches = (_c = this.node) === null || _c === void 0 ? void 0 : _c.querySelectorAll('.switch button');
        this.policeOptions = document.querySelectorAll('.fieldset-police_choice input');
        this.slider = {
            'container': document.querySelector(".slider"),
            'rail': document.querySelector(".slider_rail"),
            'thumb': document.querySelector(".slider_thumb")
        };
        this.handleClick = this.handleClick.bind(this);
        this.onModalOpen = this.onModalOpen.bind(this);
        this.handleMouseMove = this.handleMouseMove.bind(this);
        (_d = this.controlButton) === null || _d === void 0 ? void 0 : _d.addEventListener('click', this.onModalOpen);
        (_e = this.slider.container) === null || _e === void 0 ? void 0 : _e.addEventListener('click', (e) => this.accessibility.computeFontSizeFromMouse(e));
        (_f = this.slider.thumb) === null || _f === void 0 ? void 0 : _f.addEventListener('keydown', (e) => this.accessibility.computeFontSizeFromKeyboard(e));
        (_g = this.slider.thumb) === null || _g === void 0 ? void 0 : _g.addEventListener('mousedown', () => this.accessibility.isMouseDown = true);
        (_h = this.slider.container) === null || _h === void 0 ? void 0 : _h.addEventListener('mousemove', (e) => this.handleMouseMove(e));
        this.policeOptions.forEach(radio => radio.addEventListener('change', (e) => this.accessibility.setPolice(e)));
        (_j = this.switchLines) === null || _j === void 0 ? void 0 : _j.forEach(line => line.addEventListener('click', (e) => this.handleClick(e)));
    }
    onModalOpen() {
        var _a;
        (_a = this.accessibilityList) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 0);
        setTimeout(() => {
            this.accessibility.setFontSize();
        }, 50);
    }
    handleMouseMove(e) {
        if (this.accessibility.isMouseDown) {
            this.accessibility.computeFontSizeFromMouse(e);
        }
        ;
    }
    handleClick(e) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        if ((_b = (_a = this.accessibility.animationsSwitch) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.contains(e === null || e === void 0 ? void 0 : e.target)) {
            this.accessibility.switchAnimations();
        }
        ;
        if ((_d = (_c = this.accessibility.styleSwitch) === null || _c === void 0 ? void 0 : _c.parentElement) === null || _d === void 0 ? void 0 : _d.contains(e === null || e === void 0 ? void 0 : e.target)) {
            this.accessibility.switchStyle();
        }
        ;
        if ((_f = (_e = this.accessibility.pMarginBottomSwitch) === null || _e === void 0 ? void 0 : _e.parentElement) === null || _f === void 0 ? void 0 : _f.contains(e === null || e === void 0 ? void 0 : e.target)) {
            this.accessibility.switchPMarginBottom();
        }
        ;
        if ((_h = (_g = this.accessibility.lineHeightSwitch) === null || _g === void 0 ? void 0 : _g.parentElement) === null || _h === void 0 ? void 0 : _h.contains(e === null || e === void 0 ? void 0 : e.target)) {
            this.accessibility.switchLineHeight();
        }
        ;
        if ((_k = (_j = this.accessibility.wordSpacingSwitch) === null || _j === void 0 ? void 0 : _j.parentElement) === null || _k === void 0 ? void 0 : _k.contains(e === null || e === void 0 ? void 0 : e.target)) {
            this.accessibility.switchWordSpacing();
        }
        ;
        if ((_m = (_l = this.accessibility.letterSpacingSwitch) === null || _l === void 0 ? void 0 : _l.parentElement) === null || _m === void 0 ? void 0 : _m.contains(e === null || e === void 0 ? void 0 : e.target)) {
            this.accessibility.switchLetterSpacing();
        }
        ;
    }
}
