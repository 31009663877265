import VolumeSlider from './volumeSliderClass';
import TimeSlider from './timeSliderClass';
export default class VideoPlayer {
    constructor(videoPlayer, accessibility) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x;
        this.accessibility = accessibility;
        this.node = videoPlayer;
        this.language = document.documentElement.getAttribute('lang');
        this.background = document.querySelector('.portfolios-video-player_background');
        this.fullScreenAlert = this.node.querySelector('.portfolios-video-player_full-screen-alert');
        this.mainPlayButton = this.node.querySelector('.portfolios-video-player_initial-play');
        this.video = this.node.querySelector('video');
        this.controlBar = this.node.querySelector('.portfolios-video-player_control-bar');
        this.playButton = (_a = this.controlBar) === null || _a === void 0 ? void 0 : _a.querySelector('.video-play');
        this.timeSliderContainer = (_b = this.controlBar) === null || _b === void 0 ? void 0 : _b.querySelector('.video-time-line');
        this.timeSliderThumb = (_c = this.timeSliderContainer) === null || _c === void 0 ? void 0 : _c.querySelector('.slider_thumb');
        this.currentTimeElement = this.node.querySelector('.video-controls_control-bar_current-time');
        this.maxTimeElement = this.node.querySelector('.video-controls_control-bar_max-time');
        this.volumeContainer = (_d = this.controlBar) === null || _d === void 0 ? void 0 : _d.querySelector('.portfolios-video-player_control-bar_volume-container');
        this.volumeButton = (_e = this.controlBar) === null || _e === void 0 ? void 0 : _e.querySelector('.video-volume-button');
        this.volumeSliderContainer = (_f = this.controlBar) === null || _f === void 0 ? void 0 : _f.querySelector('.video-volume');
        this.volumeSliderThumb = (_g = this.volumeSliderContainer) === null || _g === void 0 ? void 0 : _g.querySelector('.slider_thumb');
        this.fullScreenButton = this.node.querySelector('.portfolios-video-player_control-bar_full-screen');
        this.fullScreenIcon = (_h = this.fullScreenButton) === null || _h === void 0 ? void 0 : _h.querySelector('.full-screen-svg');
        this.fullScreenExitIcon = (_j = this.fullScreenButton) === null || _j === void 0 ? void 0 : _j.querySelector('.full-screen-exit-svg');
        this.videoFocusElement = this.node.parentElement;
        this.isFullScreenActive = false;
        this.controlBarHide = undefined;
        this.initialize = this.initialize.bind(this);
        this.playVideo = this.playVideo.bind(this);
        this.stopVideo = this.stopVideo.bind(this);
        this.handleClickOnMainPlayButton = this.handleClickOnMainPlayButton.bind(this);
        this.handleClickOnPlayButton = this.handleClickOnPlayButton.bind(this);
        this.handleClickOnVolumeButton = this.handleClickOnVolumeButton.bind(this);
        this.handleClickOnFullScreenButton = this.handleClickOnFullScreenButton.bind(this);
        this.handleFullScreenChange = this.handleFullScreenChange.bind(this);
        this.setFullScreen = this.setFullScreen.bind(this);
        this.leaveFullScreen = this.leaveFullScreen.bind(this);
        this.handleTimeSlider = this.handleTimeSlider.bind(this);
        this.handleControlBar = this.handleControlBar.bind(this);
        this.hideControlBarOnMouseLeave = this.hideControlBarOnMouseLeave.bind(this);
        this.handleMouseOverControlBar = this.handleMouseOverControlBar.bind(this);
        this.handleKeyboard = this.handleKeyboard.bind(this);
        this.displayFocusOnVideo = this.displayFocusOnVideo.bind(this);
        this.removeFocusOnVideo = this.removeFocusOnVideo.bind(this);
        (_k = this.mainPlayButton) === null || _k === void 0 ? void 0 : _k.addEventListener('click', () => this.handleClickOnMainPlayButton());
        (_l = this.playButton) === null || _l === void 0 ? void 0 : _l.addEventListener('click', () => this.handleClickOnPlayButton());
        (_m = this.video) === null || _m === void 0 ? void 0 : _m.addEventListener('mousemove', () => this.handleControlBar());
        (_o = this.video) === null || _o === void 0 ? void 0 : _o.addEventListener('focus', () => this.displayFocusOnVideo());
        (_p = this.video) === null || _p === void 0 ? void 0 : _p.addEventListener('blur', () => this.removeFocusOnVideo());
        this.node.addEventListener('mouseleave', () => this.hideControlBarOnMouseLeave());
        (_q = this.controlBar) === null || _q === void 0 ? void 0 : _q.addEventListener('mouseover', () => this.handleMouseOverControlBar());
        (_r = this.controlBar) === null || _r === void 0 ? void 0 : _r.addEventListener('focusin', () => this.handleControlBar());
        (_s = this.controlBar) === null || _s === void 0 ? void 0 : _s.addEventListener('focusout', () => this.hideControlBarOnMouseLeave());
        (_t = this.video) === null || _t === void 0 ? void 0 : _t.addEventListener('click', () => {
            this.handleClickOnPlayButton();
            this.handleControlBar();
        });
        (_u = this.video) === null || _u === void 0 ? void 0 : _u.addEventListener('keydown', (e) => this.handleKeyboard(e));
        (_v = this.video) === null || _v === void 0 ? void 0 : _v.addEventListener('timeupdate', () => {
            this.handleTimeSlider();
            this.handleCurrentTimeElement();
        });
        (_w = this.volumeButton) === null || _w === void 0 ? void 0 : _w.addEventListener('click', () => this.handleClickOnVolumeButton());
        (_x = this.fullScreenButton) === null || _x === void 0 ? void 0 : _x.addEventListener('click', () => this.handleClickOnFullScreenButton());
        document.addEventListener("fullscreenchange", () => this.handleFullScreenChange());
    }
    initialize() {
        var _a, _b, _c, _d;
        let maxTime = 0;
        setTimeout(() => {
            var _a, _b, _c;
            if (this.video) {
                this.video.volume = 0;
                maxTime = Math.round((_a = this.video) === null || _a === void 0 ? void 0 : _a.duration);
            }
            ;
            (_b = this.timeSliderThumb) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-valuemax', `${maxTime}`);
            this.setTimeIndicator((_c = this.video) === null || _c === void 0 ? void 0 : _c.duration, this.maxTimeElement);
            this.timeSlider = new TimeSlider(this.timeSliderContainer, this.accessibility, this.video, this.playButton);
        }, 200);
        this.volumeSlider = new VolumeSlider(this.volumeSliderContainer, this.accessibility, this.video, this.volumeButton);
        (_b = (_a = this.timeSlider) === null || _a === void 0 ? void 0 : _a.thumb) === null || _b === void 0 ? void 0 : _b.addEventListener('keydown', () => this.handleControlBar());
        (_d = (_c = this.volumeSlider) === null || _c === void 0 ? void 0 : _c.thumb) === null || _d === void 0 ? void 0 : _d.addEventListener('keydown', () => this.handleControlBar());
    }
    playVideo() {
        var _a, _b, _c, _d, _e, _f;
        (_a = this.video) === null || _a === void 0 ? void 0 : _a.play();
        if (this.language === 'fr') {
            (_b = this.playButton) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-label', 'Arrêter la vidéo');
        }
        else {
            (_c = this.playButton) === null || _c === void 0 ? void 0 : _c.setAttribute('aria-label', 'Stop the video');
        }
        ;
        if ((_d = this.mainPlayButton) === null || _d === void 0 ? void 0 : _d.classList.contains('display')) {
            (_e = this.mainPlayButton) === null || _e === void 0 ? void 0 : _e.classList.remove('display');
            (_f = this.background) === null || _f === void 0 ? void 0 : _f.classList.remove('display');
        }
        ;
    }
    stopVideo() {
        var _a, _b, _c;
        (_a = this.video) === null || _a === void 0 ? void 0 : _a.pause();
        if (this.language === 'fr') {
            (_b = this.playButton) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-label', 'Lancer la vidéo');
        }
        else {
            (_c = this.playButton) === null || _c === void 0 ? void 0 : _c.setAttribute('aria-label', 'Play the video');
        }
        ;
    }
    handleClickOnFullScreenButton() {
        if (this.isFullScreenActive) {
            this.leaveFullScreen();
        }
        else {
            this.setFullScreen();
        }
        ;
    }
    setFullScreen() {
        var _a, _b;
        this.node.requestFullscreen();
        if (this.language === 'fr') {
            (_a = this.fullScreenButton) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-label', 'Quitter le mode plein écran');
        }
        else {
            (_b = this.fullScreenButton) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-label', 'Leave full screen mode');
        }
        ;
    }
    leaveFullScreen() {
        document.exitFullscreen();
        if (this.fullScreenButton)
            if (this.language === 'fr') {
                this.fullScreenButton.setAttribute('aria-label', 'Passer en mode plein écran');
            }
            else {
                this.fullScreenButton.setAttribute('aria-label', 'Go to full screen mode');
            }
        ;
    }
    handleFullScreenChange() {
        var _a, _b, _c, _d;
        this.isFullScreenActive = !this.isFullScreenActive;
        (_a = this.video) === null || _a === void 0 ? void 0 : _a.classList.toggle('full-screen');
        (_b = this.timeSlider) === null || _b === void 0 ? void 0 : _b.handleSliderThumbPosition();
        (_c = this.fullScreenIcon) === null || _c === void 0 ? void 0 : _c.classList.toggle('display');
        (_d = this.fullScreenExitIcon) === null || _d === void 0 ? void 0 : _d.classList.toggle('display');
    }
    handleClickOnMainPlayButton() {
        this.playVideo();
        this.handleControlBar();
    }
    handleClickOnPlayButton() {
        var _a;
        if ((_a = this.video) === null || _a === void 0 ? void 0 : _a.paused) {
            this.playVideo();
        }
        else {
            this.stopVideo();
        }
        ;
    }
    handleClickOnVolumeButton() {
        var _a;
        (_a = this.volumeSlider) === null || _a === void 0 ? void 0 : _a.setValueFromVolumeButton();
    }
    setTimeIndicator(time, timeIndicator) {
        if (time !== undefined) {
            const minutes = Math.floor(time / 60);
            const seconds = Math.floor(time % 60);
            if (timeIndicator) {
                if (seconds < 10) {
                    timeIndicator.innerText = `${minutes}:0${seconds}`;
                }
                else {
                    timeIndicator.innerText = `${minutes}:${seconds}`;
                }
                ;
            }
            ;
        }
        ;
    }
    handleCurrentTimeElement() {
        var _a, _b;
        this.setTimeIndicator((_a = this.video) === null || _a === void 0 ? void 0 : _a.currentTime, this.currentTimeElement);
        (_b = this.timeSlider) === null || _b === void 0 ? void 0 : _b.setAriaValues();
    }
    handleTimeSlider() {
        var _a, _b, _c;
        if (this.timeSlider)
            this.timeSlider.value = (_a = this.video) === null || _a === void 0 ? void 0 : _a.currentTime;
        (_b = this.timeSlider) === null || _b === void 0 ? void 0 : _b.handleSliderThumbPosition();
        (_c = this.timeSlider) === null || _c === void 0 ? void 0 : _c.checkVideoEnd();
    }
    handleControlBar() {
        var _a, _b;
        clearTimeout(this.controlBarHide);
        if (!((_a = this.mainPlayButton) === null || _a === void 0 ? void 0 : _a.classList.contains('display'))) {
            (_b = this.controlBar) === null || _b === void 0 ? void 0 : _b.classList.add('display');
            this.controlBarHide = setTimeout(() => {
                var _a;
                (_a = this.controlBar) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
            }, 4000);
        }
        ;
    }
    hideControlBarOnMouseLeave() {
        var _a, _b;
        if (!((_a = this.mainPlayButton) === null || _a === void 0 ? void 0 : _a.classList.contains('display'))) {
            clearTimeout(this.controlBarHide);
            (_b = this.controlBar) === null || _b === void 0 ? void 0 : _b.classList.remove('display');
        }
        ;
    }
    handleMouseOverControlBar() {
        var _a;
        clearTimeout(this.controlBarHide);
        (_a = this.controlBar) === null || _a === void 0 ? void 0 : _a.classList.add('display');
    }
    handleKeyboard(e) {
        switch (e.key) {
            case ' ':
                e.preventDefault();
                this.handleClickOnPlayButton();
                this.handleControlBar();
                break;
            default:
                break;
        }
    }
    displayFocusOnVideo() {
        var _a;
        (_a = this.videoFocusElement) === null || _a === void 0 ? void 0 : _a.classList.add('display');
    }
    removeFocusOnVideo() {
        var _a;
        (_a = this.videoFocusElement) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
    }
}
