import Modal from "./modalClass";
export default class MobileMenuModal extends Modal {
    constructor(modal, controlButton, focusOnDisplay) {
        super(modal, controlButton, focusOnDisplay);
        if (this.focusableElements) {
            this.lastFocusableElement = this.focusableElements[1];
        }
        ;
    }
}
