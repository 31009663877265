import Modal from "./modalClass";
export default class LanguageModal extends Modal {
    constructor(modal, controlButton, focusOnDisplay) {
        var _a, _b;
        super(modal, controlButton, focusOnDisplay);
        this.languageToConfirm = 'english';
        this.confirmButton = document.querySelector('.modal-lang_yes');
        this.noButton = document.querySelector('.modal-lang_no');
        this.confirm = this.confirm.bind(this);
        (_a = this.confirmButton) === null || _a === void 0 ? void 0 : _a.addEventListener('click', this.confirm);
        (_b = this.noButton) === null || _b === void 0 ? void 0 : _b.addEventListener('click', this.setDisplay);
    }
    confirm() {
        if (this.languageToConfirm === 'french') {
            localStorage.setItem('languageChange', 'true');
            localStorage.setItem('language', 'french');
            const windowLocation = window.location.href;
            if (windowLocation.includes('https://dyskredy-art.com/about')) {
                window.location.href = 'https://dyskredy-art.com/a-propos';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/contact_en')) {
                window.location.href = 'https://dyskredy-art.com/contact';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/accessibility')) {
                window.location.href = 'https://dyskredy-art.com/accessibilite';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/technopolice_en')) {
                window.location.href = 'https://dyskredy-art.com/technopolice';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/dreams')) {
                window.location.href = 'https://dyskredy-art.com/reves';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/paintings')) {
                window.location.href = 'https://dyskredy-art.com/peintures';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/photos_en')) {
                window.location.href = 'https://dyskredy-art.com/photos';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/videos_en')) {
                window.location.href = 'https://dyskredy-art.com/videos';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/en')) {
                window.location.href = 'https://dyskredy-art.com/';
            }
            ;
        }
        else {
            localStorage.setItem('language', 'english');
            localStorage.setItem('languageChange', 'true');
            const windowLocation = window.location.href;
            if (windowLocation.includes('https://dyskredy-art.com/a-propos')) {
                window.location.href = 'https://dyskredy-art.com/about';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/contact') && !windowLocation.includes('https://dyskredy-art.com/contact_en')) {
                window.location.href = 'https://dyskredy-art.com/contact_en';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/accessibilite')) {
                window.location.href = 'https://dyskredy-art.com/accessibility';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/technopolice') && !windowLocation.includes('https://dyskredy-art.com/technopolice_en')) {
                window.location.href = 'https://dyskredy-art.com/technopolice_en';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/reves')) {
                window.location.href = 'https://dyskredy-art.com/dreams';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/peintures')) {
                window.location.href = 'https://dyskredy-art.com/paintings';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/photos') && !windowLocation.includes('https://dyskredy-art.com/photos_en')) {
                window.location.href = 'https://dyskredy-art.com/photos_en';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/videos') && !windowLocation.includes('https://dyskredy-art.com/videos_en')) {
                window.location.href = 'https://dyskredy-art.com/videos_en';
            }
            else if (windowLocation.includes('https://dyskredy-art.com/') && !windowLocation.includes('https://dyskredy-art.com/en')) {
                window.location.href = 'https://dyskredy-art.com/en';
            }
            ;
        }
        ;
    }
}
