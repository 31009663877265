export default class Form {
    constructor(form) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        this.node = form;
        this.language = document.documentElement.getAttribute('lang');
        this.fields = (_a = this.node) === null || _a === void 0 ? void 0 : _a.querySelectorAll('input:not([type=submit]),textarea');
        this.requiredFields = (_b = this.node) === null || _b === void 0 ? void 0 : _b.querySelectorAll('input[required],textarea[required]');
        this.invalidFields = [];
        this.invalidFieldsAlerts = (_c = this.node) === null || _c === void 0 ? void 0 : _c.querySelectorAll('.invalid-field-alert');
        this.submitButton = (_d = this.node) === null || _d === void 0 ? void 0 : _d.querySelector('input[type=submit]');
        this.pageTitleElement = document.querySelector('title');
        this.pageTitleText = (_e = this.pageTitleElement) === null || _e === void 0 ? void 0 : _e.innerText;
        this.isReadyToSubmit = false;
        this.successMessage = (_f = this.node) === null || _f === void 0 ? void 0 : _f.querySelector('.success-message');
        this.submitErrorMessage = (_g = this.node) === null || _g === void 0 ? void 0 : _g.querySelector('.submit-error-message');
        this.invalidFieldsMainAlert = (_h = this.node) === null || _h === void 0 ? void 0 : _h.querySelector('.invalid-fields-message');
        this.invalidCaptchaMainAlert = (_j = this.node) === null || _j === void 0 ? void 0 : _j.querySelector('.invalid-captcha-message');
        this.checkRequiredFields = this.checkRequiredFields.bind(this);
        this.applyEffect = this.applyEffect.bind(this);
        this.setSuccessForm = this.setSuccessForm.bind(this);
        this.setFailForm = this.setFailForm.bind(this);
        this.cleanMainErrorMessages = this.cleanMainErrorMessages.bind(this);
        this.cleanInvalidFields = this.cleanInvalidFields.bind(this);
        this.resetForm = this.resetForm.bind(this);
        (_k = this.requiredFields) === null || _k === void 0 ? void 0 : _k.forEach(field => field.addEventListener('invalid', (e) => e.preventDefault()));
        (_l = this.submitButton) === null || _l === void 0 ? void 0 : _l.addEventListener('click', () => {
            this.checkRequiredFields();
        });
        (_m = this.node) === null || _m === void 0 ? void 0 : _m.addEventListener('submit', (e) => {
            var _a;
            e.preventDefault();
            const captchaClientResponse = grecaptcha.getResponse();
            if (captchaClientResponse.length > 0) { //check on client side
                let formData = new FormData(this.node);
                let captchaServerResponse = new URLSearchParams(formData);
                const options = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    },
                    body: captchaServerResponse
                };
                fetch('/app/check_captcha', options) //check on server side
                    .then(res => {
                    if (res.ok) {
                        this.cleanMainErrorMessages();
                        this.applyEffect();
                    }
                    else {
                        this.setFailForm();
                    }
                    ;
                })
                    .catch(err => {
                    this.setFailForm();
                });
            }
            else {
                this.cleanMainErrorMessages();
                (_a = this.invalidCaptchaMainAlert) === null || _a === void 0 ? void 0 : _a.classList.add('display');
            }
            ;
        });
    }
    checkRequiredFields() {
        var _a, _b;
        this.isReadyToSubmit = true;
        this.cleanInvalidFields();
        this.invalidFields = [];
        (_a = this.requiredFields) === null || _a === void 0 ? void 0 : _a.forEach(field => {
            var _a, _b, _c;
            if (field.matches('input:invalid') || field.matches('textarea:invalid')) {
                (_a = this.invalidFields) === null || _a === void 0 ? void 0 : _a.push(field);
                field.classList.add('invalid');
                (_b = field.previousElementSibling) === null || _b === void 0 ? void 0 : _b.classList.add('display');
                const alertId = (_c = field.previousElementSibling) === null || _c === void 0 ? void 0 : _c.getAttribute('id');
                if (alertId)
                    field.setAttribute('aria-describedby', alertId);
                this.isReadyToSubmit = false;
            }
            ;
        });
        if (!this.isReadyToSubmit) {
            this.cleanMainErrorMessages();
            (_b = this.invalidFieldsMainAlert) === null || _b === void 0 ? void 0 : _b.classList.add('display');
            if (this.pageTitleElement && this.pageTitleText !== undefined) {
                this.pageTitleElement.innerText = this.pageTitleText;
                if (this.language === 'fr') {
                    this.pageTitleElement.innerText = this.pageTitleText + "- Erreur: champ(s) invalide(s) !";
                }
                else {
                    this.pageTitleElement.innerText = this.pageTitleText + "- Error: invalid field(s) !";
                }
                ;
            }
            ;
            if (this.invalidFields)
                this.invalidFields[0].focus();
        }
        ;
    }
    applyEffect() {
        //depends on form
    }
    setSuccessForm() {
        var _a;
        this.resetForm();
        (_a = this.successMessage) === null || _a === void 0 ? void 0 : _a.classList.add('display');
        if (this.pageTitleElement && this.pageTitleText !== undefined) {
            this.pageTitleElement.innerText = this.pageTitleText;
            if (this.language === 'fr') {
                this.pageTitleElement.innerText = this.pageTitleText + "- Message envoyé avec succès !";
            }
            else {
                this.pageTitleElement.innerText = this.pageTitleText + "- Message successfully sent !";
            }
            ;
        }
        ;
    }
    setFailForm() {
        var _a;
        this.cleanMainErrorMessages();
        (_a = this.submitErrorMessage) === null || _a === void 0 ? void 0 : _a.classList.add('display');
        if (this.pageTitleElement && this.pageTitleText !== undefined) {
            this.pageTitleElement.innerText = this.pageTitleText;
            if (this.language === 'fr') {
                this.pageTitleElement.innerText = this.pageTitleText + "- Erreur, formulaire non soumis !";
            }
            else {
                this.pageTitleElement.innerText = this.pageTitleText + "- Error, form not submitted !";
            }
            ;
        }
        ;
    }
    cleanMainErrorMessages() {
        var _a, _b, _c, _d;
        (_a = this.successMessage) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
        (_b = this.submitErrorMessage) === null || _b === void 0 ? void 0 : _b.classList.remove('display');
        (_c = this.invalidFieldsMainAlert) === null || _c === void 0 ? void 0 : _c.classList.remove('display');
        (_d = this.invalidCaptchaMainAlert) === null || _d === void 0 ? void 0 : _d.classList.remove('display');
    }
    cleanInvalidFields() {
        var _a;
        this.fields.forEach(field => {
            field.removeAttribute('aria-describedby');
            field.classList.remove('invalid');
        });
        (_a = this.invalidFieldsAlerts) === null || _a === void 0 ? void 0 : _a.forEach(alert => alert.classList.remove('display'));
    }
    resetForm() {
        this.fields.forEach(field => {
            field.value = "";
        });
        this.cleanInvalidFields();
        this.cleanMainErrorMessages();
        if (this.pageTitleElement && this.pageTitleText !== undefined)
            this.pageTitleElement.innerText = this.pageTitleText;
        this.isReadyToSubmit = false;
    }
}
