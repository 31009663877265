import Modal from "./modalClass";
export default class ShortcutsModal extends Modal {
    constructor(modal, controlButton, focusOnDisplay = 'firstElement', activeElement) {
        var _a, _b;
        super(modal, controlButton, focusOnDisplay);
        this.elementToFocusOnClose = activeElement;
        this.topFocusTrapping = document.querySelector('.top-modal-shortcuts');
        this.bottomFocusTrapping = document.querySelector('.bottom-modal-shortcuts');
        (_a = this.topFocusTrapping) === null || _a === void 0 ? void 0 : _a.addEventListener('focus', this.handleTopFocusTrapping);
        (_b = this.bottomFocusTrapping) === null || _b === void 0 ? void 0 : _b.addEventListener('focus', this.handleBottomFocusTrapping);
    }
    setDisplay() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        this.display = !this.display;
        const bodyBis = document.querySelector('.body-bis');
        if (this.display) {
            (_a = this.node) === null || _a === void 0 ? void 0 : _a.classList.add('display');
            if (this.focusOnDisplay === 'firstElement') {
                (_b = this.firstFocusableElement) === null || _b === void 0 ? void 0 : _b.focus();
            }
            else {
                if (typeof this.focusOnDisplay !== 'string')
                    (_c = this.focusOnDisplay) === null || _c === void 0 ? void 0 : _c.focus();
            }
            ;
            bodyBis === null || bodyBis === void 0 ? void 0 : bodyBis.classList.add('has-modal');
            (_d = this.topFocusTrapping) === null || _d === void 0 ? void 0 : _d.classList.add('display');
            (_e = this.bottomFocusTrapping) === null || _e === void 0 ? void 0 : _e.classList.add('display');
        }
        else {
            (_f = this.node) === null || _f === void 0 ? void 0 : _f.classList.remove('display');
            bodyBis === null || bodyBis === void 0 ? void 0 : bodyBis.classList.remove('has-modal');
            (_g = this.topFocusTrapping) === null || _g === void 0 ? void 0 : _g.classList.remove('display');
            (_h = this.bottomFocusTrapping) === null || _h === void 0 ? void 0 : _h.classList.remove('display');
            (_j = this.elementToFocusOnClose) === null || _j === void 0 ? void 0 : _j.focus();
        }
        ;
    }
}
