export default class Slide {
    constructor(section) {
        var _a, _b;
        this.language = document.documentElement.getAttribute('lang');
        this.topFocusTrapping = document.querySelector(".top-modal");
        this.bottomFocusTrapping = document.querySelector(".bottom-modal");
        this.node = null;
        this.section = section;
        // this.closeButton = this.node?.querySelector('.slide_close');
        // this.leftButton = this.node?.querySelector('.slide_left-button');
        // this.rightButton = this.node?.querySelector('.slide_right-button');
        this.individualButtons = [];
        // this.slideContainer = this.node?.querySelector('.slide_view');
        // this.individualButtonsContainer = this.node?.querySelector('.slide_individual-buttons-container');
        this.pictures = this.section.querySelectorAll('.portfolios-pictures');
        this.picturesViews = [];
        this.currentPicture = this.pictures[0];
        this.indexCurrentPicture = 0;
        this.setCurrentPicture = this.setCurrentPicture.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.handleLeftButton = this.handleLeftButton.bind(this);
        this.handleRightButton = this.handleRightButton.bind(this);
        this.handleIndividualButtons = this.handleIndividualButtons.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.setCurrentIndividualButton = this.setCurrentIndividualButton.bind(this);
        this.setAriaLabel = this.setAriaLabel.bind(this);
        this.handleTopFocusTrapping = this.handleTopFocusTrapping.bind(this);
        this.handleBottomFocusTrapping = this.handleBottomFocusTrapping.bind(this);
        (_a = this.topFocusTrapping) === null || _a === void 0 ? void 0 : _a.addEventListener('focus', this.handleTopFocusTrapping);
        (_b = this.bottomFocusTrapping) === null || _b === void 0 ? void 0 : _b.addEventListener('focus', this.handleBottomFocusTrapping);
    }
    initialize() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        //create sliders frames
        const sections = document.querySelectorAll('.section-pictures');
        const modalSlide = document.querySelector('.modal-slide');
        const index = Array.from(sections).indexOf(this.section);
        const slide = document.createElement('div');
        slide.classList.add('slide');
        slide.classList.add(`slide-${index}`);
        slide.setAttribute('role', 'group');
        slide.setAttribute('aria-roledescription', 'carousel');
        const windowLocation = window.location.href;
        if (windowLocation.includes('technopolice')) {
            slide.setAttribute('aria-label', 'technopolice');
        }
        else if (windowLocation.includes('reves')) {
            slide.setAttribute('aria-label', 'reves');
        }
        else if (windowLocation.includes('dreams')) {
            slide.setAttribute('aria-label', 'dreams');
        }
        else if (windowLocation.includes('peintures')) {
            slide.setAttribute('aria-label', 'peintures');
        }
        else if (windowLocation.includes('paintings')) {
            slide.setAttribute('aria-label', 'paintings');
        }
        else if (windowLocation.includes('photos')) {
            slide.setAttribute('aria-label', 'photos');
        }
        ;
        modalSlide === null || modalSlide === void 0 ? void 0 : modalSlide.appendChild(slide);
        this.node = slide;
        const closeButton = document.createElement('button');
        closeButton.classList.add('slide_close');
        closeButton.innerHTML = '&#10761;';
        if (this.language === 'fr') {
            closeButton.setAttribute('aria-label', "Fermer le caroussel");
        }
        else {
            closeButton.setAttribute('aria-label', "Close the caroussel dialog");
        }
        ;
        this.node.appendChild(closeButton);
        this.closeButton = closeButton;
        const leftButton = document.createElement('button');
        leftButton.classList.add('slide_arrow-button', 'slide_left-button');
        leftButton.innerHTML = '&#9664;';
        this.node.appendChild(leftButton);
        this.leftButton = leftButton;
        const slideView = document.createElement('div');
        slideView.classList.add('slide_view');
        slideView.setAttribute('id', 'slide-container');
        slideView.setAttribute('role', 'group');
        slideView.setAttribute('aria-roledescription', 'slide');
        slideView.setAttribute('aria-atomic', 'false');
        slideView.setAttribute('aria-live', 'polite');
        this.node.appendChild(slideView);
        this.slideContainer = slideView;
        const rightButton = document.createElement('button');
        rightButton.classList.add('slide_arrow-button', 'slide_right-button');
        rightButton.innerHTML = '&#9654;';
        this.node.appendChild(rightButton);
        this.rightButton = rightButton;
        const individualButtonsContainer = document.createElement('div');
        individualButtonsContainer.classList.add('slide_individual-buttons-container');
        individualButtonsContainer.setAttribute('role', 'group');
        if (this.language === 'fr') {
            individualButtonsContainer.setAttribute('aria-label', "Choisir l'image à afficher");
        }
        else {
            individualButtonsContainer.setAttribute('aria-label', "Chose which picture to show");
        }
        ;
        this.node.appendChild(individualButtonsContainer);
        this.individualButtonsContainer = individualButtonsContainer;
        //display left and right buttons or not
        if (this.pictures.length === 1) {
            (_a = this.leftButton) === null || _a === void 0 ? void 0 : _a.setAttribute('disabled', '');
            (_b = this.rightButton) === null || _b === void 0 ? void 0 : _b.setAttribute('disabled', '');
        }
        ;
        this.pictures.forEach(picture => {
            var _a, _b;
            //create picture views
            const src = picture.getAttribute('src');
            const alt = picture.getAttribute('alt');
            let newPicture = document.createElement('img');
            newPicture.setAttribute("tabindex", "0");
            if (src)
                newPicture.setAttribute('src', src.replace('small', 'full'));
            if (alt)
                newPicture.setAttribute('alt', alt);
            newPicture.classList.add('slide_view_picture');
            slideView === null || slideView === void 0 ? void 0 : slideView.appendChild(newPicture);
            this.picturesViews.push(newPicture);
            //create individual buttons
            let newIndividualButton = document.createElement('button');
            newIndividualButton.classList.add('slide_individual-button');
            newIndividualButton.setAttribute('aria-labelledby', 'slide-container');
            newIndividualButton.setAttribute('tabindex', '-1');
            (_a = this.individualButtonsContainer) === null || _a === void 0 ? void 0 : _a.appendChild(newIndividualButton);
            (_b = this.individualButtons) === null || _b === void 0 ? void 0 : _b.push(newIndividualButton);
        });
        (_c = this.node) === null || _c === void 0 ? void 0 : _c.addEventListener('keydown', (e) => this.handleKeyDown(e));
        this.pictures.forEach(picture => { var _a; return (_a = picture.parentElement) === null || _a === void 0 ? void 0 : _a.addEventListener('click', () => this.open(picture)); });
        (_d = this.closeButton) === null || _d === void 0 ? void 0 : _d.addEventListener('click', this.close);
        (_e = this.leftButton) === null || _e === void 0 ? void 0 : _e.addEventListener('click', this.handleLeftButton);
        (_f = this.rightButton) === null || _f === void 0 ? void 0 : _f.addEventListener('click', this.handleRightButton);
        (_g = this.individualButtons) === null || _g === void 0 ? void 0 : _g.forEach(button => button.addEventListener('click', () => this.handleIndividualButtons(button)));
        (_h = this.picturesViews) === null || _h === void 0 ? void 0 : _h.forEach(picture => picture.addEventListener('contextmenu', (e) => e.preventDefault()));
    }
    handleLeftButton() {
        if (this.indexCurrentPicture === 0) {
            this.indexCurrentPicture = this.picturesViews.length - 1;
        }
        else {
            if (this.indexCurrentPicture !== undefined)
                this.indexCurrentPicture--;
        }
        ;
        this.setCurrentPicture();
        this.setAriaLabel();
        this.setCurrentIndividualButton();
    }
    handleRightButton() {
        if (this.indexCurrentPicture === this.picturesViews.length - 1) {
            this.indexCurrentPicture = 0;
        }
        else {
            if (this.indexCurrentPicture !== undefined)
                this.indexCurrentPicture++;
        }
        ;
        this.setCurrentPicture();
        this.setAriaLabel();
        this.setCurrentIndividualButton();
    }
    handleIndividualButtons(button) {
        var _a;
        this.indexCurrentPicture = (_a = this.individualButtons) === null || _a === void 0 ? void 0 : _a.indexOf(button);
        this.setCurrentPicture();
        this.setAriaLabel();
        this.setCurrentIndividualButton();
    }
    handleKeyDown(e) {
        var _a, _b;
        switch (e.key) {
            case 'Escape':
                this.close();
                break;
            case 'ArrowLeft':
                if ((_a = this.individualButtonsContainer) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement)) {
                    this.handleLeftButton();
                    if (this.individualButtons && this.indexCurrentPicture !== undefined)
                        this.individualButtons[this.indexCurrentPicture].focus();
                }
                else {
                    this.picturesViews.forEach(picture => {
                        if (picture === document.activeElement) {
                            this.handleLeftButton();
                            setTimeout(() => {
                                var _a;
                                (_a = this.currentPicture) === null || _a === void 0 ? void 0 : _a.focus();
                            }, 100);
                        }
                        ;
                    });
                }
                ;
                break;
            case 'ArrowRight':
                if ((_b = this.individualButtonsContainer) === null || _b === void 0 ? void 0 : _b.contains(document.activeElement)) {
                    this.handleRightButton();
                    if (this.individualButtons && this.indexCurrentPicture !== undefined)
                        this.individualButtons[this.indexCurrentPicture].focus();
                }
                else {
                    this.picturesViews.forEach(picture => {
                        if (picture === document.activeElement) {
                            this.handleRightButton();
                            setTimeout(() => {
                                var _a;
                                (_a = this.currentPicture) === null || _a === void 0 ? void 0 : _a.focus();
                            }, 100);
                        }
                        ;
                    });
                }
                ;
                break;
            default:
                break;
        }
    }
    setCurrentPicture() {
        var _a;
        if (this.indexCurrentPicture !== undefined)
            this.currentPicture = this.picturesViews[this.indexCurrentPicture];
        this.picturesViews.forEach(element => {
            element.classList.remove('display');
        });
        (_a = this.currentPicture) === null || _a === void 0 ? void 0 : _a.classList.add('display');
    }
    setAriaLabel() {
        var _a, _b;
        if (this.indexCurrentPicture !== undefined) {
            if (document.documentElement.getAttribute('lang') === 'en') {
                (_a = this.slideContainer) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-label', `${this.indexCurrentPicture + 1} of ${this.picturesViews.length}`);
            }
            else {
                (_b = this.slideContainer) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-label', `${this.indexCurrentPicture + 1} sur ${this.picturesViews.length}`);
            }
            ;
        }
        ;
    }
    setCurrentIndividualButton() {
        if (this.individualButtons) {
            this.individualButtons.forEach(button => {
                button.setAttribute('aria-disabled', 'false');
                button.setAttribute('tabindex', '-1');
            });
            if (this.indexCurrentPicture !== undefined) {
                this.individualButtons[this.indexCurrentPicture].setAttribute('aria-disabled', 'true');
                this.individualButtons[this.indexCurrentPicture].setAttribute('tabindex', '0');
            }
            ;
        }
        ;
    }
    open(picture) {
        var _a, _b, _c, _d, _e, _f;
        const bodyBis = document.querySelector('.body-bis');
        (_a = this.node) === null || _a === void 0 ? void 0 : _a.classList.add('display');
        this.currentPicture = this.picturesViews.find(element => { var _a; return ((_a = element.getAttribute('src')) === null || _a === void 0 ? void 0 : _a.replace('full', 'small')) === picture.getAttribute('src'); });
        if (this.currentPicture)
            this.indexCurrentPicture = this.picturesViews.indexOf(this.currentPicture);
        this.picturesViews.forEach(element => element.classList.remove('display'));
        (_b = this.currentPicture) === null || _b === void 0 ? void 0 : _b.classList.add('display');
        (_c = this.currentPicture) === null || _c === void 0 ? void 0 : _c.setAttribute('tabindex', '0');
        this.setCurrentIndividualButton();
        this.setAriaLabel();
        bodyBis === null || bodyBis === void 0 ? void 0 : bodyBis.classList.add('has-modal');
        (_d = this.topFocusTrapping) === null || _d === void 0 ? void 0 : _d.classList.add('display');
        (_e = this.bottomFocusTrapping) === null || _e === void 0 ? void 0 : _e.classList.add('display');
        (_f = this.closeButton) === null || _f === void 0 ? void 0 : _f.focus();
    }
    close() {
        var _a, _b, _c, _d;
        const bodyBis = document.querySelector('.body-bis');
        (_a = this.node) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
        bodyBis === null || bodyBis === void 0 ? void 0 : bodyBis.classList.remove('has-modal');
        (_b = this.topFocusTrapping) === null || _b === void 0 ? void 0 : _b.classList.remove('display');
        (_c = this.bottomFocusTrapping) === null || _c === void 0 ? void 0 : _c.classList.remove('display');
        if (this.indexCurrentPicture !== undefined)
            (_d = this.pictures[this.indexCurrentPicture].parentElement) === null || _d === void 0 ? void 0 : _d.focus();
    }
    handleTopFocusTrapping() {
        if (this.individualButtons && this.indexCurrentPicture !== undefined)
            this.individualButtons[this.indexCurrentPicture].focus();
    }
    handleBottomFocusTrapping() {
        var _a;
        (_a = this.closeButton) === null || _a === void 0 ? void 0 : _a.focus();
    }
}
