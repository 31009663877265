import setActiveStyleSheet from './setActiveStyleSheet';
export default class Accessibility {
    constructor(style, siteAnimations, fontSize, police, pMarginBottom, lineHeight, wordSpacing, letterSpacing) {
        var _a, _b, _c, _d, _e;
        this.initialize = false;
        this.accessibilityList = document.querySelector('.modal-access_liste');
        this.accessibilityListScrollTop = (_a = this.accessibilityList) === null || _a === void 0 ? void 0 : _a.scrollTop;
        this.style = style;
        this.styleSwitch = document.querySelector(".switch-contrast");
        this.styleSwitchMobile = document.querySelector(".switch-contrast.mobile");
        this.isAnimations = true;
        this.siteAnimations = siteAnimations;
        this.systemAnimations = true;
        this.animationsSwitch = document.querySelector(".switch-animations");
        this.initialFontSize = 12;
        this.fontSize = fontSize;
        this.fontSizeMax = 300;
        this.slider = {
            'container': document.querySelector(".slider"),
            'rail': document.querySelector(".slider_rail"),
            'thumb': document.querySelector(".slider_thumb")
        };
        this.sliderOffsetTop = (_c = (_b = this.slider) === null || _b === void 0 ? void 0 : _b.container) === null || _c === void 0 ? void 0 : _c.offsetTop;
        this.isMouseDown = false;
        this.police = police;
        this.policeOptions = document.querySelectorAll('.fieldset-police_choice input');
        this.policeRoboto = (_d = document.querySelector('input[value="Roboto"]')) === null || _d === void 0 ? void 0 : _d.parentElement;
        this.policeOpenDyslexic = (_e = document.querySelector('input[value="openDyslexic"]')) === null || _e === void 0 ? void 0 : _e.parentElement;
        this.pMarginBottom = pMarginBottom;
        this.pMarginBottomSwitch = document.querySelector(".switch-p-margin-bottom");
        this.styledElements = document.querySelectorAll('span, a, p, h1, h2, h3, h4, h5, h6, label, li');
        this.lineHeight = lineHeight;
        this.lineHeightSwitch = document.querySelector(".switch-line-height");
        this.wordSpacing = wordSpacing;
        this.wordSpacingSwitch = document.querySelector(".switch-word-spacing");
        this.letterSpacing = letterSpacing;
        this.letterSpacingSwitch = document.querySelector(".switch-letter-spacing");
        this.switchStyle = this.switchStyle.bind(this);
        this.switchAnimations = this.switchAnimations.bind(this);
        this.computeFontSizeFromMouse = this.computeFontSizeFromMouse.bind(this);
        this.computeFontSizeFromKeyboard = this.computeFontSizeFromKeyboard.bind(this);
        this.setFontSize = this.setFontSize.bind(this);
        this.handleSliderThumbPosition = this.handleSliderThumbPosition.bind(this);
        this.keepSliderPositionFixed = this.keepSliderPositionFixed.bind(this);
        this.setPolice = this.setPolice.bind(this);
        this.switchPMarginBottom = this.switchPMarginBottom.bind(this);
        this.switchLineHeight = this.switchLineHeight.bind(this);
        this.switchWordSpacing = this.switchWordSpacing.bind(this);
        this.switchLetterSpacing = this.switchLetterSpacing.bind(this);
        this.handleFooter = this.handleFooter.bind(this);
        this.handleCharacters = this.handleCharacters.bind(this);
    }
    switchStyle() {
        var _a, _b, _c, _d;
        if (!this.initialize) {
            if (this.style === 'default') {
                this.style = 'contrast';
            }
            else {
                this.style = 'default';
            }
            ;
            localStorage.setItem('style', this.style);
        }
        ;
        if (this.style === 'default') {
            setActiveStyleSheet("mainStyle");
            (_a = this.styleSwitch) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-checked', 'false');
            (_b = this.styleSwitchMobile) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-checked', 'false');
            localStorage.setItem('style', this.style);
        }
        else {
            setActiveStyleSheet("mainStyleContrast");
            (_c = this.styleSwitch) === null || _c === void 0 ? void 0 : _c.setAttribute('aria-checked', 'true');
            (_d = this.styleSwitchMobile) === null || _d === void 0 ? void 0 : _d.setAttribute('aria-checked', 'true');
            localStorage.setItem('style', this.style);
        }
        ;
    }
    switchAnimations() {
        var _a, _b;
        if (!this.initialize) {
            if (this.siteAnimations === 'true') {
                this.siteAnimations = 'false';
            }
            else {
                this.siteAnimations = 'true';
            }
            ;
            localStorage.setItem('animations', this.siteAnimations);
        }
        ;
        if (this.siteAnimations === 'true') {
            (_a = this.animationsSwitch) === null || _a === void 0 ? void 0 : _a.setAttribute("aria-checked", "true");
        }
        else {
            (_b = this.animationsSwitch) === null || _b === void 0 ? void 0 : _b.setAttribute("aria-checked", "false");
        }
        ;
        if (globalThis.matchMedia("(prefers-reduced-motion:reduce)").matches) {
            this.systemAnimations = false;
        }
        else {
            this.systemAnimations = true;
        }
        ;
        if (!this.systemAnimations || this.siteAnimations === 'false') {
            this.isAnimations = false;
            document.body.classList.remove('animate');
        }
        else {
            this.isAnimations = true;
            document.body.classList.add('animate');
        }
        ;
    }
    computeFontSizeFromMouse(e) {
        var _a;
        if (this.slider)
            if (this.slider.rail)
                this.fontSize = (e.clientX - this.slider.rail.getBoundingClientRect().left) / ((_a = this.slider.rail) === null || _a === void 0 ? void 0 : _a.offsetWidth) * (200 - 100) + 100;
        this.setFontSize();
    }
    computeFontSizeFromKeyboard(e) {
        const basicIncrement = 5;
        const biggerIncrement = 20;
        switch (e.key) {
            case 'ArrowLeft':
                if (this.fontSize > 100 + basicIncrement) {
                    this.fontSize -= basicIncrement;
                }
                else {
                    this.fontSize = 100;
                }
                ;
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (this.fontSize > 100 + basicIncrement) {
                    this.fontSize -= basicIncrement;
                }
                else {
                    this.fontSize = 100;
                }
                ;
                break;
            case 'PageDown':
                e.preventDefault();
                if (this.fontSize > 100 + biggerIncrement) {
                    this.fontSize -= biggerIncrement;
                }
                else {
                    this.fontSize = 100;
                }
                ;
                break;
            case 'ArrowRight':
                if (this.fontSize < 200) {
                    this.fontSize += basicIncrement;
                }
                else {
                    this.fontSize = 200;
                }
                ;
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (this.fontSize < 200) {
                    this.fontSize += basicIncrement;
                }
                else {
                    this.fontSize = 200;
                }
                ;
                break;
            case 'PageUp':
                e.preventDefault();
                if (this.fontSize < 200 - biggerIncrement) {
                    this.fontSize += biggerIncrement;
                }
                else {
                    this.fontSize = 200;
                }
                ;
                break;
            case 'Home':
                e.preventDefault();
                this.fontSize = 100;
                break;
            case 'End':
                e.preventDefault();
                this.fontSize = 200;
                break;
        }
        ;
        this.setFontSize();
    }
    setFontSize() {
        var _a, _b, _c, _d;
        if (!this.initialize) {
            localStorage.setItem("fontSize", this.fontSize.toString());
        }
        ;
        this.accessibilityListScrollTop = (_a = this.accessibilityList) === null || _a === void 0 ? void 0 : _a.scrollTop;
        this.sliderOffsetTop = (_b = this.slider.container) === null || _b === void 0 ? void 0 : _b.offsetTop;
        document.documentElement.style.fontSize = this.fontSize / 100 * this.initialFontSize + "px";
        (_c = this.slider.thumb) === null || _c === void 0 ? void 0 : _c.setAttribute('aria-valuenow', this.fontSize.toString());
        (_d = this.slider.thumb) === null || _d === void 0 ? void 0 : _d.setAttribute('aria-valuetext', `${this.fontSize}%`);
        this.handleSliderThumbPosition();
        this.handleCharacters();
        this.handleFooter();
    }
    handleSliderThumbPosition() {
        var _a, _b, _c;
        const position = (this.fontSize - 100) / (200 - 100) * (((_a = this.slider) === null || _a === void 0 ? void 0 : _a.rail) ? this.slider.rail.offsetWidth : 0);
        const rectification = position / (((_b = this.slider) === null || _b === void 0 ? void 0 : _b.rail) ? this.slider.rail.offsetWidth : 1) * (((_c = this.slider) === null || _c === void 0 ? void 0 : _c.thumb) ? this.slider.thumb.offsetWidth : 0);
        const visualPosition = position - rectification;
        if (this.slider.thumb)
            this.slider.thumb.style.left = `${visualPosition}px`;
        this.keepSliderPositionFixed();
    }
    keepSliderPositionFixed() {
        var _a, _b;
        const offset = (this.sliderOffsetTop && this.slider.container) ? (this.sliderOffsetTop - this.slider.container.offsetTop) : 0;
        if (this.accessibilityListScrollTop) {
            console.log(this.accessibilityListScrollTop);
            if (this.accessibilityListScrollTop === 0) {
                (_a = this.accessibilityList) === null || _a === void 0 ? void 0 : _a.scrollTo(0, 100 - offset); // trouver un autre moyen car scrolltop varie en fct de la hauteur de la fenêtre
            }
            else {
                (_b = this.accessibilityList) === null || _b === void 0 ? void 0 : _b.scrollTo(0, this.accessibilityListScrollTop - offset);
            }
        }
        ;
    }
    setPolice(e) {
        var _a, _b;
        if (!this.initialize) {
            if ((_a = this.policeRoboto) === null || _a === void 0 ? void 0 : _a.contains(e === null || e === void 0 ? void 0 : e.target)) {
                this.police = 'roboto';
            }
            ;
            if ((_b = this.policeOpenDyslexic) === null || _b === void 0 ? void 0 : _b.contains(e === null || e === void 0 ? void 0 : e.target)) {
                this.police = 'openDyslexic';
            }
            ;
        }
        ;
        localStorage.setItem("police", this.police);
        document.documentElement.style.fontFamily = this.police;
        const particularElements = document.querySelectorAll('button, select, input, textarea');
        particularElements.forEach(element => element.style.fontFamily = this.police);
        if (this.police == 'roboto') {
            this.policeOptions[0].checked = true;
        }
        else {
            this.policeOptions[1].checked = true;
        }
        ;
        this.handleCharacters();
    }
    switchPMarginBottom() {
        var _a, _b;
        if (!this.initialize) {
            if (this.pMarginBottom === 'true') {
                this.pMarginBottom = 'false';
            }
            else {
                this.pMarginBottom = 'true';
            }
            ;
            localStorage.setItem('pMarginBottom', this.pMarginBottom);
        }
        ;
        if (this.pMarginBottom === 'true') {
            (_a = this.pMarginBottomSwitch) === null || _a === void 0 ? void 0 : _a.setAttribute("aria-checked", "true");
            Array.from(document.querySelectorAll('p:not(.copyright_text)')).forEach(paragraph => paragraph.style.marginBottom = `${(this.fontSize / 100 * this.initialFontSize) * 2}px`);
        }
        else {
            (_b = this.pMarginBottomSwitch) === null || _b === void 0 ? void 0 : _b.setAttribute("aria-checked", "false");
            Array.from(document.getElementsByTagName('p')).forEach(paragraph => paragraph.style.marginBottom = 'auto');
        }
        ;
        this.handleCharacters();
    }
    switchLineHeight() {
        var _a, _b;
        if (!this.initialize) {
            if (this.lineHeight === 'true') {
                this.lineHeight = 'false';
            }
            else {
                this.lineHeight = 'true';
            }
            ;
            localStorage.setItem('lineHeight', this.lineHeight);
        }
        ;
        if (this.lineHeight === 'true') {
            (_a = this.lineHeightSwitch) === null || _a === void 0 ? void 0 : _a.setAttribute("aria-checked", "true");
            Array.from(this.styledElements).forEach(element => element.style.lineHeight = '150%');
        }
        else {
            (_b = this.lineHeightSwitch) === null || _b === void 0 ? void 0 : _b.setAttribute("aria-checked", "false");
            Array.from(this.styledElements).forEach(element => element.style.lineHeight = 'normal');
        }
        ;
        this.handleCharacters();
    }
    switchWordSpacing() {
        var _a, _b;
        if (!this.initialize) {
            if (this.wordSpacing === 'true') {
                this.wordSpacing = 'false';
            }
            else {
                this.wordSpacing = 'true';
            }
            ;
            localStorage.setItem('wordSpacing', this.wordSpacing);
        }
        ;
        if (this.wordSpacing === 'true') {
            (_a = this.wordSpacingSwitch) === null || _a === void 0 ? void 0 : _a.setAttribute("aria-checked", "true");
            Array.from(this.styledElements).forEach(element => element.style.wordSpacing = `${(this.fontSize / 100 * this.initialFontSize) * 0.16}px`);
        }
        else {
            (_b = this.wordSpacingSwitch) === null || _b === void 0 ? void 0 : _b.setAttribute("aria-checked", "false");
            Array.from(this.styledElements).forEach(element => element.style.wordSpacing = 'normal');
        }
        ;
        this.handleCharacters();
    }
    switchLetterSpacing() {
        var _a, _b;
        if (!this.initialize) {
            if (this.letterSpacing === 'true') {
                this.letterSpacing = 'false';
            }
            else {
                this.letterSpacing = 'true';
            }
            ;
            localStorage.setItem('letterSpacing', this.letterSpacing);
        }
        ;
        if (this.letterSpacing === 'true') {
            (_a = this.letterSpacingSwitch) === null || _a === void 0 ? void 0 : _a.setAttribute("aria-checked", "true");
            Array.from(this.styledElements).forEach(element => element.style.letterSpacing = `${(this.fontSize / 100 * this.initialFontSize) * 0.16}px`);
        }
        else {
            (_b = this.letterSpacingSwitch) === null || _b === void 0 ? void 0 : _b.setAttribute("aria-checked", "false");
            Array.from(this.styledElements).forEach(element => element.style.letterSpacing = 'normal');
        }
        ;
        this.handleCharacters();
    }
    handleFooter() {
        const footer = document.getElementById("footer");
        if (this.fontSize <= 175) {
            footer === null || footer === void 0 ? void 0 : footer.classList.add("footer-font-size-3");
            footer === null || footer === void 0 ? void 0 : footer.classList.remove("footer-font-size-2", "footer-font-size-4");
            if (this.fontSize <= 140) {
                footer === null || footer === void 0 ? void 0 : footer.classList.add("footer-font-size-2");
                footer === null || footer === void 0 ? void 0 : footer.classList.remove("footer-font-size-3", "footer-font-size-4");
                if (this.fontSize <= 115) {
                    footer === null || footer === void 0 ? void 0 : footer.classList.remove("footer-font-size-2", "footer-font-size-3", "footer-font-size-4");
                }
                ;
            }
            ;
        }
        else {
            footer === null || footer === void 0 ? void 0 : footer.classList.add("footer-font-size-4");
            footer === null || footer === void 0 ? void 0 : footer.classList.remove("footer-font-size-2", "footer-font-size-3");
        }
        ;
    }
    handleCharacters() {
        const mainMenu = document.querySelector(".menu-container");
        const treeCharacter = document.querySelectorAll(".header_tree");
        const dragon = document.querySelectorAll(".header_dragon");
        if (mainMenu)
            if (document.documentElement.scrollTop > mainMenu.offsetTop) {
                treeCharacter.forEach(e => (e.classList.remove("display")));
                dragon.forEach(e => (e.classList.remove("display")));
            }
            else {
                if (this.letterSpacing === 'false' && this.police === 'roboto') {
                    if (this.fontSize <= 110) {
                        treeCharacter.forEach(e => (e.classList.add("display")));
                    }
                    else {
                        treeCharacter.forEach(e => (e.classList.remove("display")));
                    }
                    ;
                }
                else {
                    treeCharacter.forEach(e => (e.classList.remove("display")));
                }
                ;
                if (this.fontSize <= 175) {
                    dragon.forEach(e => (e.classList.add("display")));
                }
                else {
                    treeCharacter.forEach(e => (e.classList.remove("display")));
                    dragon.forEach(e => (e.classList.remove("display")));
                }
                ;
            }
        ;
    }
}
