import Select from './selectClass';
export default class LanguageSelect extends Select {
    constructor(select, languageModal) {
        super(select);
        this.languageModal = languageModal;
        this.handleChoice = this.handleChoice.bind(this);
    }
    handleChoice() {
        this.languageModal.languageToConfirm = this.activeDescendantId;
        this.languageModal.setDisplay();
    }
}
