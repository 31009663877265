import MainMenu from "./mainMenuClass";
export default class VerticalMainMenu extends MainMenu {
    constructor(mainMenu) {
        super(mainMenu);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }
    handleKeyDown(e) {
        var _a, _b, _c, _d, _e, _f;
        const temporaryAlphabet = 'abcdefghijiklmnopqrstuvwxyz';
        const alphabet = temporaryAlphabet.split('');
        switch (e.key) {
            case 'Escape':
                this.autoDisplaySubMenus = false;
                for (let submenu of this.subMenus) {
                    this.closeSubMenu(submenu);
                    if ((_a = submenu.node) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement)) {
                        (_b = submenu.controlLink) === null || _b === void 0 ? void 0 : _b.focus();
                        if (submenu.controlLink)
                            this.handleFocusOnMainLink(submenu.controlLink);
                    }
                    ;
                }
                ;
                break;
            case 'Tab':
                setTimeout(() => {
                    for (let submenu of this.subMenus) {
                        if (submenu.isExpanded) {
                            submenu.isExpanded = false;
                            this.closeSubMenu(submenu);
                        }
                        ;
                    }
                    ;
                    this.autoDisplaySubMenus = false;
                }, 50);
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (this.mainLinks) {
                    for (let mainlink of Array.from(this.mainLinks)) {
                        if (mainlink === document.activeElement) {
                            let index = Array.from(this.mainLinks).indexOf(mainlink);
                            if (index === this.mainLinks.length - 1) {
                                index = 0;
                            }
                            else {
                                index++;
                            }
                            ;
                            this.mainLinks[index].focus();
                            this.handleFocusOnMainLink(this.mainLinks[index]);
                            break;
                        }
                        ;
                    }
                    ;
                }
                ;
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (this.mainLinks) {
                    for (let mainlink of Array.from(this.mainLinks)) {
                        if (mainlink === document.activeElement) {
                            let index = Array.from(this.mainLinks).indexOf(mainlink);
                            if (index === 0) {
                                index = this.mainLinks.length - 1;
                            }
                            else {
                                index--;
                            }
                            ;
                            this.mainLinks[index].focus();
                            this.handleFocusOnMainLink(this.mainLinks[index]);
                            break;
                        }
                        ;
                    }
                    ;
                }
                ;
                break;
            default:
                break;
        }
        ;
        if (alphabet.includes(e.key)) {
            if (this.mainLinks)
                if (Array.from(this.mainLinks).includes(document.activeElement)) {
                    let mainLinkToFocus = null;
                    if (this.mainLinksShortcuts[e.key]) {
                        if ((_c = this.mainLinksShortcuts[e.key]) === null || _c === void 0 ? void 0 : _c.includes(document.activeElement)) {
                            let index = (_d = this.mainLinksShortcuts[e.key]) === null || _d === void 0 ? void 0 : _d.indexOf(document.activeElement);
                            if (index === this.mainLinksShortcuts[e.key].length - 1) {
                                index = 0;
                            }
                            else {
                                if (index)
                                    index++;
                            }
                            ;
                            if (index)
                                mainLinkToFocus = this.mainLinksShortcuts[e.key][index];
                        }
                        else {
                            mainLinkToFocus = this.mainLinksShortcuts[e.key][0];
                        }
                        ;
                        mainLinkToFocus === null || mainLinkToFocus === void 0 ? void 0 : mainLinkToFocus.focus();
                        if (mainLinkToFocus)
                            this.handleFocusOnMainLink(mainLinkToFocus);
                    }
                    ;
                }
                else {
                    let subLinkToFocus = null;
                    for (let submenu of this.subMenus) {
                        if (Array.from(submenu.links).includes(document.activeElement)) {
                            if (submenu.shortcuts[e.key]) {
                                if ((_e = submenu.shortcuts[e.key]) === null || _e === void 0 ? void 0 : _e.includes(document.activeElement)) {
                                    let index = (_f = submenu.shortcuts[e.key]) === null || _f === void 0 ? void 0 : _f.indexOf(document.activeElement);
                                    if (index === submenu.shortcuts[e.key].length - 1) {
                                        index = 0;
                                    }
                                    else {
                                        if (index)
                                            index++;
                                    }
                                    ;
                                    if (index)
                                        subLinkToFocus = submenu.shortcuts[e.key][index];
                                }
                                else {
                                    subLinkToFocus = submenu.shortcuts[e.key][0];
                                }
                                ;
                                subLinkToFocus === null || subLinkToFocus === void 0 ? void 0 : subLinkToFocus.focus();
                            }
                            ;
                        }
                        ;
                    }
                    ;
                }
            ;
        }
        ;
    }
}
