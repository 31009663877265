export default class Modal {
    constructor(modal, controlButton, focusOnDisplay = 'firstElement') {
        var _a, _b, _c, _d, _e;
        this.node = document.querySelector(`.${modal}`);
        this.display = false;
        this.controlButton = controlButton;
        this.topFocusTrapping = document.querySelector(".top-modal");
        this.bottomFocusTrapping = document.querySelector(".bottom-modal");
        this.focusableElements = (_a = this.node) === null || _a === void 0 ? void 0 : _a.querySelectorAll('button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)');
        this.firstFocusableElement = this.focusableElements ? this.focusableElements[0] : null;
        this.lastFocusableElement = this.focusableElements ? this.focusableElements[this.focusableElements.length - 1] : null;
        this.focusOnDisplay = focusOnDisplay;
        this.setDisplay = this.setDisplay.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleTopFocusTrapping = this.handleTopFocusTrapping.bind(this);
        this.handleBottomFocusTrapping = this.handleBottomFocusTrapping.bind(this);
        (_b = this.firstFocusableElement) === null || _b === void 0 ? void 0 : _b.addEventListener('click', () => this.setDisplay());
        (_c = this.node) === null || _c === void 0 ? void 0 : _c.addEventListener('keydown', (e) => this.handleKeyDown(e));
        (_d = this.topFocusTrapping) === null || _d === void 0 ? void 0 : _d.addEventListener('focus', this.handleTopFocusTrapping);
        (_e = this.bottomFocusTrapping) === null || _e === void 0 ? void 0 : _e.addEventListener('focus', this.handleBottomFocusTrapping);
    }
    setDisplay() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        this.display = !this.display;
        const bodyBis = document.querySelector('.body-bis');
        if (this.display) {
            (_a = this.node) === null || _a === void 0 ? void 0 : _a.classList.add('display');
            if (this.focusOnDisplay === 'firstElement') {
                (_b = this.firstFocusableElement) === null || _b === void 0 ? void 0 : _b.focus();
            }
            else {
                if (typeof this.focusOnDisplay !== 'string')
                    (_c = this.focusOnDisplay) === null || _c === void 0 ? void 0 : _c.focus();
            }
            ;
            bodyBis === null || bodyBis === void 0 ? void 0 : bodyBis.classList.add('has-modal');
            (_d = this.topFocusTrapping) === null || _d === void 0 ? void 0 : _d.classList.add('display');
            (_e = this.bottomFocusTrapping) === null || _e === void 0 ? void 0 : _e.classList.add('display');
        }
        else {
            (_f = this.node) === null || _f === void 0 ? void 0 : _f.classList.remove('display');
            (_g = this.controlButton) === null || _g === void 0 ? void 0 : _g.focus();
            bodyBis === null || bodyBis === void 0 ? void 0 : bodyBis.classList.remove('has-modal');
            (_h = this.topFocusTrapping) === null || _h === void 0 ? void 0 : _h.classList.remove('display');
            (_j = this.bottomFocusTrapping) === null || _j === void 0 ? void 0 : _j.classList.remove('display');
        }
        ;
    }
    handleTopFocusTrapping() {
        var _a;
        (_a = this.lastFocusableElement) === null || _a === void 0 ? void 0 : _a.focus();
    }
    handleBottomFocusTrapping() {
        var _a;
        (_a = this.firstFocusableElement) === null || _a === void 0 ? void 0 : _a.focus();
    }
    handleKeyDown(e) {
        switch (e.key) {
            case 'Escape':
                this.setDisplay();
                break;
            default:
                break;
        }
        ;
    }
}
