export default class Slider {
    constructor(slider, accessibility) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        this.accessibility = accessibility;
        this.node = slider;
        this.thumb = (_a = this.node) === null || _a === void 0 ? void 0 : _a.querySelector('.slider_thumb');
        this.rail = (_b = this.node) === null || _b === void 0 ? void 0 : _b.querySelector('.slider_rail');
        this.progression = (_c = this.rail) === null || _c === void 0 ? void 0 : _c.querySelector('.slider_progression');
        this.basicIncrement = 5;
        this.biggerIncrement = 20;
        this.value = 0;
        this.valueMax = parseInt((_d = this.thumb) === null || _d === void 0 ? void 0 : _d.getAttribute('aria-valuemax'));
        this.valueMin = parseInt((_e = this.thumb) === null || _e === void 0 ? void 0 : _e.getAttribute('aria-valuemin'));
        this.handleMouseMove = this.handleMouseMove.bind(this);
        (_f = this.node) === null || _f === void 0 ? void 0 : _f.addEventListener('click', (e) => this.computeValueFromMouse(e));
        (_g = this.thumb) === null || _g === void 0 ? void 0 : _g.addEventListener('keydown', (e) => this.computeValueFromKeyboard(e));
        (_h = this.thumb) === null || _h === void 0 ? void 0 : _h.addEventListener('mousedown', () => this.accessibility.isMouseDown = true);
        (_j = this.node) === null || _j === void 0 ? void 0 : _j.addEventListener('mousemove', (e) => this.handleMouseMove(e));
    }
    handleMouseMove(e) {
        if (this.accessibility.isMouseDown) {
            this.computeValueFromMouse(e);
        }
        ;
    }
    computeValueFromMouse(e) {
        var _a;
        if (this.rail)
            this.value = Math.round((e.clientX - this.rail.getBoundingClientRect().left) / ((_a = this.rail) === null || _a === void 0 ? void 0 : _a.offsetWidth) * (this.valueMax - this.valueMin) + this.valueMin);
        this.setAriaValues();
        this.handleSliderThumbPosition();
        this.applyEffect();
    }
    computeValueFromKeyboard(e) {
        switch (e.key) {
            case 'ArrowLeft':
                if (this.value !== undefined)
                    if (this.value > this.valueMin + this.basicIncrement) {
                        this.value -= this.basicIncrement;
                    }
                    else {
                        this.value = this.valueMin;
                    }
                ;
                break;
            case 'ArrowDown':
                e.preventDefault();
                if (this.value !== undefined)
                    if (this.value > this.valueMin + this.basicIncrement) {
                        this.value -= this.basicIncrement;
                    }
                    else {
                        this.value = this.valueMin;
                    }
                ;
                break;
            case 'PageDown':
                e.preventDefault();
                if (this.value !== undefined)
                    if (this.value > this.valueMin + this.biggerIncrement) {
                        this.value -= this.biggerIncrement;
                    }
                    else {
                        this.value = this.valueMin;
                    }
                ;
                break;
            case 'ArrowRight':
                if (this.value !== undefined)
                    if (this.value < this.valueMax) {
                        this.value += this.basicIncrement;
                    }
                    else {
                        this.value = this.valueMax;
                    }
                ;
                break;
            case 'ArrowUp':
                e.preventDefault();
                if (this.value !== undefined)
                    if (this.value < this.valueMax) {
                        this.value += this.basicIncrement;
                    }
                    else {
                        this.value = this.valueMax;
                    }
                ;
                break;
            case 'PageUp':
                e.preventDefault();
                if (this.value !== undefined)
                    if (this.value < this.valueMax - this.biggerIncrement) {
                        this.value += this.biggerIncrement;
                    }
                    else {
                        this.value = this.valueMax;
                    }
                ;
                break;
            case 'Home':
                e.preventDefault();
                this.value = this.valueMin;
                break;
            case 'End':
                e.preventDefault();
                this.value = this.valueMax;
                break;
        }
        ;
        this.setAriaValues();
        this.handleSliderThumbPosition();
        this.applyEffect();
    }
    setAriaValues() {
        var _a;
        (_a = this.thumb) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-valuenow', `${this.value}`);
        this.setAriaValueText();
    }
    setAriaValueText() {
        //depends on the slider
    }
    handleSliderThumbPosition() {
        var _a;
        let position = 0;
        if (this.value !== undefined)
            position = Math.round(this.value / (this.valueMax - this.valueMin) * (this.rail ? this.rail.offsetWidth : 0));
        console.log(position);
        const rectification = position / (this.rail ? this.rail.offsetWidth : 1) * (this.thumb ? this.thumb.offsetWidth : 0);
        const visualPosition = position - rectification;
        if (this.thumb)
            this.thumb.style.left = `${visualPosition}px`;
        if (this.thumb)
            console.log(position, ((_a = this.thumb) === null || _a === void 0 ? void 0 : _a.offsetWidth) / 2);
        this.handleProgressionLength(visualPosition);
    }
    handleProgressionLength(thumbPosition) {
        var _a;
        if (this.progression && this.thumb)
            this.progression.style.width = `${thumbPosition + ((_a = this.thumb) === null || _a === void 0 ? void 0 : _a.offsetWidth) / 2}px`;
    }
    applyEffect() {
        //depends on the slider
    }
}
