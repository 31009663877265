import Slider from "./sliderClass";
export default class TimeSlider extends Slider {
    constructor(slider, accessibility, video, playButton) {
        super(slider, accessibility);
        this.language = document.documentElement.getAttribute('lang');
        this.video = video;
        this.playButton = playButton;
        this.applyEffect = this.applyEffect.bind(this);
        this.setPlayButtonToPlay = this.setPlayButtonToPlay.bind(this);
    }
    applyEffect() {
        if (this.video && this.value !== undefined)
            this.video.currentTime = this.value;
        this.checkVideoEnd();
    }
    checkVideoEnd() {
        var _a;
        if (this.video && this.value !== undefined) {
            if (Math.floor(this.value) === Math.floor((_a = this.video) === null || _a === void 0 ? void 0 : _a.duration)) {
                this.setPlayButtonToPlay();
            }
            ;
        }
        ;
    }
    setPlayButtonToPlay() {
        var _a;
        (_a = this.playButton) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-label', 'Lancer la vidéo');
    }
    setAriaValues() {
        var _a, _b;
        if (this.video)
            (_a = this.thumb) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-valuenow', `${Math.floor((_b = this.video) === null || _b === void 0 ? void 0 : _b.currentTime)}`);
    }
    setAriaValueText() {
        var _a, _b, _c;
        let minutes = 0;
        let seconds = 0;
        if (this.video) {
            minutes = Math.floor(((_a = this.video) === null || _a === void 0 ? void 0 : _a.currentTime) / 60);
            seconds = Math.floor(((_b = this.video) === null || _b === void 0 ? void 0 : _b.currentTime) % 60);
        }
        ;
        let ariaValueText = "";
        if (this.language === 'fr') {
            ariaValueText = `${minutes} minutes et ${seconds} secondes`;
        }
        else {
            ariaValueText = `${minutes} minutes and ${seconds} seconds`;
        }
        ;
        (_c = this.thumb) === null || _c === void 0 ? void 0 : _c.setAttribute('aria-valuetext', ariaValueText);
    }
}
