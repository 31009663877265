export default class Previews {
    constructor(accessibility) {
        this.accessibility = accessibility;
        this.previews = document.querySelectorAll('.preview');
        this.offsets = {};
        this.previews.forEach(preview => this.offsets[preview.classList[1]] = preview.offsetTop);
        this.handleAppear = this.handleAppear.bind(this);
        window.addEventListener('scroll', () => this.handleAppear());
        this.previews.forEach(preview => preview.addEventListener('focus', () => this.handleFocus(preview)));
    }
    handleAppear() {
        var _a;
        if (!this.accessibility.isAnimations) {
            (_a = this.previews) === null || _a === void 0 ? void 0 : _a.forEach(preview => {
                preview.classList.add('display');
            });
        }
        else {
            for (let prop in this.offsets) {
                if (document.documentElement.scrollTop > this.offsets[prop] - 250) {
                    const previewToDisplay = Array.from(this.previews).find(preview => preview.classList.contains(prop));
                    previewToDisplay === null || previewToDisplay === void 0 ? void 0 : previewToDisplay.classList.add("display");
                }
                ;
            }
            ;
        }
        ;
    }
    ;
    handleFocus(preview) {
        scrollTo(0, preview.offsetTop + 150); //'+150' pour être sûr de faire apparaître le suivant
    }
}
