export default class Select {
    constructor(select) {
        var _a, _b, _c, _d, _e, _f, _g;
        this.node = document.querySelector(`.${select}`);
        this.isExpanded = false;
        this.listbox = (_a = this.node) === null || _a === void 0 ? void 0 : _a.querySelector('ul');
        this.value = (_b = this.node) === null || _b === void 0 ? void 0 : _b.querySelector('span:first-child');
        this.options = (_c = this.listbox) === null || _c === void 0 ? void 0 : _c.querySelectorAll('li');
        this.activeDescendantElement = this.options ? this.options[0] : null;
        this.activeDescendantId = (_d = this.activeDescendantElement) === null || _d === void 0 ? void 0 : _d.getAttribute('Id');
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.expand = this.expand.bind(this);
        this.reduce = this.reduce.bind(this);
        this.setActiveItem = this.setActiveItem.bind(this);
        this.setAriaAttributes = this.setAriaAttributes.bind(this);
        (_e = this.node) === null || _e === void 0 ? void 0 : _e.addEventListener('keydown', (e) => this.handleKeyDown(e));
        (_f = this.node) === null || _f === void 0 ? void 0 : _f.addEventListener('click', (e) => this.handleClick(e));
        (_g = this.node) === null || _g === void 0 ? void 0 : _g.addEventListener('blur', (e) => { setTimeout(() => { this.reduce(); }, 200); });
    }
    handleChoice() {
    }
    handleKeyDown(e) {
        let index = 0;
        if (this.options)
            if (this.activeDescendantElement)
                index = Array.from(this.options).indexOf(this.activeDescendantElement);
        if (this.options)
            switch (e.key) {
                case 'Enter':
                    e.preventDefault();
                    if (!this.isExpanded) {
                        this.expand();
                    }
                    else {
                        if (this.options[index].getAttribute('aria-selected') === 'false') {
                            this.handleChoice();
                        }
                        ;
                        this.reduce();
                    }
                    ;
                    break;
                case ' ':
                    e.preventDefault();
                    if (!this.isExpanded) {
                        this.expand();
                    }
                    else {
                        if (this.options[index].getAttribute('aria-selected') === 'false') {
                            this.handleChoice();
                        }
                        ;
                        this.reduce();
                    }
                    ;
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    if (!this.isExpanded) {
                        this.expand();
                    }
                    else {
                        if (index !== this.options.length - 1) {
                            index++;
                        }
                        ;
                    }
                    ;
                    this.activeDescendantElement = this.options[index];
                    this.activeDescendantId = this.activeDescendantElement.getAttribute('id');
                    this.setActiveItem();
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    if (!this.isExpanded) {
                        this.expand();
                    }
                    else {
                        if (index !== 0) {
                            index--;
                        }
                        ;
                    }
                    ;
                    this.activeDescendantElement = this.options[index];
                    this.activeDescendantId = this.activeDescendantElement.getAttribute('id');
                    this.setActiveItem();
                    break;
                case 'Escape':
                    this.reduce();
                    break;
                default:
                    break;
            }
        ;
    }
    handleClick(e) {
        var _a;
        if (this.isExpanded) {
            if (this.activeDescendantElement) {
                this.reduce();
                if ((_a = this.listbox) === null || _a === void 0 ? void 0 : _a.contains(e.target)) {
                    this.activeDescendantElement = e.target;
                    this.activeDescendantId = this.activeDescendantElement.getAttribute('id');
                    this.setActiveItem();
                    const element = e.target;
                    if (element.getAttribute('aria-selected') === 'false') {
                        this.handleChoice();
                    }
                    ;
                }
                ;
            }
            ;
        }
        else {
            this.expand();
        }
        ;
    }
    expand() {
        var _a;
        this.isExpanded = true;
        (_a = this.listbox) === null || _a === void 0 ? void 0 : _a.classList.add('display');
        this.setActiveItem();
        this.setAriaAttributes();
    }
    reduce() {
        var _a;
        this.isExpanded = false;
        (_a = this.listbox) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
        this.setAriaAttributes();
    }
    setActiveItem() {
        var _a, _b;
        (_a = this.activeDescendantElement) === null || _a === void 0 ? void 0 : _a.classList.add('active-item');
        (_b = this.options) === null || _b === void 0 ? void 0 : _b.forEach(option => {
            if (option !== this.activeDescendantElement) {
                option.classList.remove('active-item');
            }
            ;
        });
        this.setAriaAttributes();
    }
    setAriaAttributes() {
        var _a, _b, _c;
        if (this.isExpanded) {
            (_a = this.node) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-expanded', 'true');
        }
        else {
            (_b = this.node) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-expanded', 'false');
        }
        ;
        if (this.activeDescendantId)
            (_c = this.node) === null || _c === void 0 ? void 0 : _c.setAttribute('aria-activedescendant', this.activeDescendantId);
    }
}
