export default class MainMenu {
    constructor(mainMenu) {
        var _a, _b, _c, _d, _e, _f;
        this.node = document.getElementById(mainMenu);
        this.mainLinks = (_a = this.node) === null || _a === void 0 ? void 0 : _a.querySelectorAll('.nav_link_heading');
        this.mainLinksShortcuts = {};
        if (this.mainLinks) {
            for (let i = 0; i < this.mainLinks.length; i++) {
                const firstLetter = this.mainLinks[i].innerText.substring(0, 1).toLowerCase();
                if (this.mainLinksShortcuts[firstLetter]) {
                    (_b = this.mainLinksShortcuts[firstLetter]) === null || _b === void 0 ? void 0 : _b.push(this.mainLinks[i]);
                }
                else {
                    this.mainLinksShortcuts[firstLetter] = [this.mainLinks[i]];
                }
                ;
            }
            ;
        }
        ;
        this.subMenus = [];
        let subMenus = this.node ? Array.from(this.node.querySelectorAll('.submenu')) : null;
        subMenus === null || subMenus === void 0 ? void 0 : subMenus.forEach(submenu => {
            class SubMenu {
                constructor(submenu) {
                    this.node = submenu;
                    this.controlLink = submenu.previousElementSibling;
                    this.links = submenu.querySelectorAll('a');
                    this.isExpanded = false;
                    this.shortcuts = {};
                }
            }
            let newSubMenu = new SubMenu(submenu);
            this.subMenus.push(newSubMenu);
        });
        for (let submenu of this.subMenus) {
            for (let j = 0; j < submenu.links.length; j++) {
                const firstLetter = submenu.links[j].innerText.substring(0, 1).toLowerCase();
                if (submenu.shortcuts[firstLetter]) {
                    (_c = submenu.shortcuts[firstLetter]) === null || _c === void 0 ? void 0 : _c.push(submenu.links[j]);
                }
                else {
                    submenu.shortcuts[firstLetter] = [submenu.links[j]];
                }
                ;
            }
            ;
        }
        ;
        this.autoDisplaySubMenus = false;
        this.handleEscapeOnDocument = this.handleEscapeOnDocument.bind(this);
        this.handleFocusOnMainLink = this.handleFocusOnMainLink.bind(this);
        this.handleMouseOverMainLink = this.handleMouseOverMainLink.bind(this);
        this.handleMouseOutMainLink = this.handleMouseOutMainLink.bind(this);
        this.openSubMenu = this.openSubMenu.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        document.addEventListener('keydown', (e) => this.handleEscapeOnDocument(e));
        (_d = this.node) === null || _d === void 0 ? void 0 : _d.addEventListener('keydown', (e) => this.handleKeyDown(e));
        (_e = this.mainLinks) === null || _e === void 0 ? void 0 : _e.forEach(link => { var _a; return (_a = link.parentElement) === null || _a === void 0 ? void 0 : _a.addEventListener('mouseover', () => this.handleMouseOverMainLink(link.parentElement)); });
        (_f = this.mainLinks) === null || _f === void 0 ? void 0 : _f.forEach(link => { var _a; return (_a = link.parentElement) === null || _a === void 0 ? void 0 : _a.addEventListener('mouseout', () => this.handleMouseOutMainLink(link.parentElement)); });
    }
    handleFocusOnMainLink(link) {
        var _a;
        (_a = this.mainLinks) === null || _a === void 0 ? void 0 : _a.forEach(link => link.setAttribute('tabindex', '-1'));
        link === null || link === void 0 ? void 0 : link.setAttribute('tabindex', '0');
        for (let submenu of this.subMenus) {
            if (submenu.controlLink === link) {
                if (this.autoDisplaySubMenus) {
                    this.openSubMenu(submenu);
                }
                ;
            }
            ;
        }
        ;
    }
    handleMouseOverMainLink(line) {
        var _a;
        for (let submenu of this.subMenus) {
            if (((_a = submenu.controlLink) === null || _a === void 0 ? void 0 : _a.parentElement) === line) {
                if (!submenu.isExpanded) {
                    this.openSubMenu(submenu);
                }
                ;
            }
            ;
        }
        ;
    }
    handleMouseOutMainLink(line) {
        var _a;
        for (let submenu of this.subMenus) {
            if (((_a = submenu.controlLink) === null || _a === void 0 ? void 0 : _a.parentElement) === line) {
                if (submenu.isExpanded) {
                    this.closeSubMenu(submenu);
                }
                ;
            }
            ;
        }
        ;
    }
    closeSubMenu(subMenu) {
        var _a, _b, _c, _d, _e, _f, _g;
        subMenu.isExpanded = false;
        (_a = subMenu.node) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
        (_c = (_b = subMenu.node) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.classList.remove('submenu-displayed');
        (_d = subMenu.controlLink) === null || _d === void 0 ? void 0 : _d.setAttribute('aria-expanded', 'false');
        (_e = subMenu.controlLink) === null || _e === void 0 ? void 0 : _e.setAttribute('tabindex', '0');
        (_g = (_f = subMenu.controlLink) === null || _f === void 0 ? void 0 : _f.querySelector('.navlink-arrow')) === null || _g === void 0 ? void 0 : _g.classList.remove('up');
    }
    openSubMenu(subMenu) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        for (let submenu of this.subMenus) {
            this.closeSubMenu(submenu);
        }
        ;
        subMenu.isExpanded = true;
        (_a = subMenu.node) === null || _a === void 0 ? void 0 : _a.classList.add('display');
        (_c = (_b = subMenu.node) === null || _b === void 0 ? void 0 : _b.parentElement) === null || _c === void 0 ? void 0 : _c.classList.add('submenu-displayed');
        (_d = subMenu.controlLink) === null || _d === void 0 ? void 0 : _d.classList.add('submenu-displayed');
        (_e = subMenu.controlLink) === null || _e === void 0 ? void 0 : _e.setAttribute('aria-expanded', 'true');
        (_f = subMenu.controlLink) === null || _f === void 0 ? void 0 : _f.setAttribute('tabindex', '-1');
        (_h = (_g = subMenu.controlLink) === null || _g === void 0 ? void 0 : _g.querySelector('.navlink-arrow')) === null || _h === void 0 ? void 0 : _h.classList.add('up');
    }
    handleEscapeOnDocument(e) {
        var _a;
        console.log('escape');
        if (e.key === 'Escape' && !((_a = this.node) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement))) {
            console.log('close submenus');
            this.subMenus.forEach(submenu => { var _a; return (_a = submenu === null || submenu === void 0 ? void 0 : submenu.node) === null || _a === void 0 ? void 0 : _a.classList.remove('display'); });
        }
        ;
    }
    handleKeyDown(e) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        const temporaryAlphabet = 'abcdefghijiklmnopqrstuvwxyz';
        const alphabet = temporaryAlphabet.split('');
        switch (e.key) {
            case 'Escape':
                this.autoDisplaySubMenus = false;
                for (let submenu of this.subMenus) {
                    this.closeSubMenu(submenu);
                    if ((_a = submenu.node) === null || _a === void 0 ? void 0 : _a.contains(document.activeElement)) {
                        (_b = submenu.controlLink) === null || _b === void 0 ? void 0 : _b.focus();
                        if (submenu.controlLink)
                            this.handleFocusOnMainLink(submenu.controlLink);
                    }
                    ;
                }
                ;
                break;
            case 'Tab':
                setTimeout(() => {
                    for (let submenu of this.subMenus) {
                        if (submenu.isExpanded) {
                            submenu.isExpanded = false;
                            this.closeSubMenu(submenu);
                        }
                        ;
                    }
                    ;
                    this.autoDisplaySubMenus = false;
                }, 50);
                break;
            case 'ArrowRight':
                e.preventDefault();
                for (let submenu of this.subMenus) {
                    this.closeSubMenu(submenu);
                    if ((_c = submenu.node) === null || _c === void 0 ? void 0 : _c.contains(document.activeElement)) {
                        (_d = submenu.controlLink) === null || _d === void 0 ? void 0 : _d.focus();
                    }
                    ;
                }
                ;
                if (this.mainLinks) {
                    for (let mainlink of Array.from(this.mainLinks)) {
                        if (mainlink === document.activeElement) {
                            let index = Array.from(this.mainLinks).indexOf(mainlink);
                            if (index === this.mainLinks.length - 1) {
                                index = 0;
                            }
                            else {
                                index++;
                            }
                            ;
                            this.mainLinks[index].focus();
                            this.handleFocusOnMainLink(this.mainLinks[index]);
                            break;
                        }
                        ;
                    }
                    ;
                }
                ;
                break;
            case 'ArrowLeft':
                e.preventDefault();
                for (let submenu of this.subMenus) {
                    this.closeSubMenu(submenu);
                    if ((_e = submenu.node) === null || _e === void 0 ? void 0 : _e.contains(document.activeElement)) {
                        (_f = submenu.controlLink) === null || _f === void 0 ? void 0 : _f.focus();
                    }
                    ;
                }
                ;
                if (this.mainLinks) {
                    for (let mainlink of Array.from(this.mainLinks)) {
                        if (mainlink === document.activeElement) {
                            let index = Array.from(this.mainLinks).indexOf(mainlink);
                            if (index === 0) {
                                index = this.mainLinks.length - 1;
                            }
                            else {
                                index--;
                            }
                            ;
                            this.mainLinks[index].focus();
                            this.handleFocusOnMainLink(this.mainLinks[index]);
                            break;
                        }
                        ;
                    }
                    ;
                }
                ;
                break;
            case 'ArrowDown':
                e.preventDefault();
                for (let submenu of this.subMenus) {
                    if ((_g = submenu.node) === null || _g === void 0 ? void 0 : _g.contains(document.activeElement)) {
                        let index = Array.from(submenu.links).indexOf(document.activeElement);
                        if (index === submenu.links.length - 1) {
                            index = 0;
                        }
                        else {
                            index++;
                        }
                        ;
                        submenu.links[index].focus();
                    }
                    ;
                }
                ;
                if (this.mainLinks)
                    for (let mainlink of Array.from(this.mainLinks)) {
                        if (mainlink === document.activeElement) {
                            for (let submenu of this.subMenus) {
                                if (submenu.controlLink === document.activeElement) {
                                    if (!this.autoDisplaySubMenus) {
                                        this.openSubMenu(submenu);
                                        this.autoDisplaySubMenus = true;
                                    }
                                    ;
                                    submenu.links[0].focus();
                                }
                                ;
                            }
                            ;
                        }
                        ;
                    }
                ;
                break;
            case 'ArrowUp':
                e.preventDefault();
                for (let submenu of this.subMenus) {
                    if ((_h = submenu.node) === null || _h === void 0 ? void 0 : _h.contains(document.activeElement)) {
                        let index = Array.from(submenu.links).indexOf(document.activeElement);
                        if (index === 0) {
                            index = submenu.links.length - 1;
                        }
                        else {
                            index--;
                        }
                        ;
                        submenu.links[index].focus();
                    }
                    ;
                }
                ;
                if (this.mainLinks)
                    for (let mainlink of Array.from(this.mainLinks)) {
                        if (mainlink === document.activeElement) {
                            for (let submenu of this.subMenus) {
                                if (submenu.controlLink === document.activeElement) {
                                    if (!this.autoDisplaySubMenus) {
                                        this.openSubMenu(submenu);
                                        this.autoDisplaySubMenus = true;
                                    }
                                    ;
                                    submenu.links[submenu.links.length - 1].focus();
                                }
                                ;
                            }
                            ;
                        }
                        ;
                    }
                ;
                break;
            default:
                break;
        }
        ;
        if (alphabet.includes(e.key)) {
            if (this.mainLinks)
                if (Array.from(this.mainLinks).includes(document.activeElement)) {
                    let mainLinkToFocus = null;
                    if (this.mainLinksShortcuts[e.key]) {
                        if ((_j = this.mainLinksShortcuts[e.key]) === null || _j === void 0 ? void 0 : _j.includes(document.activeElement)) {
                            let index = (_k = this.mainLinksShortcuts[e.key]) === null || _k === void 0 ? void 0 : _k.indexOf(document.activeElement);
                            if (index === this.mainLinksShortcuts[e.key].length - 1) {
                                index = 0;
                            }
                            else {
                                if (index !== undefined)
                                    index++;
                            }
                            ;
                            if (index !== undefined)
                                mainLinkToFocus = this.mainLinksShortcuts[e.key][index];
                        }
                        else {
                            mainLinkToFocus = this.mainLinksShortcuts[e.key][0];
                        }
                        ;
                        mainLinkToFocus === null || mainLinkToFocus === void 0 ? void 0 : mainLinkToFocus.focus();
                        if (mainLinkToFocus)
                            this.handleFocusOnMainLink(mainLinkToFocus);
                    }
                    ;
                }
                else {
                    let subLinkToFocus = null;
                    for (let submenu of this.subMenus) {
                        if (Array.from(submenu.links).includes(document.activeElement)) {
                            if (submenu.shortcuts[e.key]) {
                                if ((_l = submenu.shortcuts[e.key]) === null || _l === void 0 ? void 0 : _l.includes(document.activeElement)) {
                                    let index = (_m = submenu.shortcuts[e.key]) === null || _m === void 0 ? void 0 : _m.indexOf(document.activeElement);
                                    if (index === submenu.shortcuts[e.key].length - 1) {
                                        index = 0;
                                    }
                                    else {
                                        if (index !== undefined)
                                            index++;
                                    }
                                    ;
                                    if (index !== undefined)
                                        subLinkToFocus = submenu.shortcuts[e.key][index];
                                }
                                else {
                                    subLinkToFocus = submenu.shortcuts[e.key][0];
                                }
                                ;
                                subLinkToFocus === null || subLinkToFocus === void 0 ? void 0 : subLinkToFocus.focus();
                            }
                            ;
                        }
                        ;
                    }
                    ;
                }
            ;
        }
        ;
    }
}
