export default class Copyright {
    constructor() {
        var _a, _b, _c, _d;
        this.node = document.querySelector(".copyright_text");
        this.container = document.querySelector(".copyright");
        this.handleKeyDown = this.handleKeyDown.bind(this);
        (_a = this.container) === null || _a === void 0 ? void 0 : _a.addEventListener('mouseover', () => { var _a; return (_a = this.node) === null || _a === void 0 ? void 0 : _a.classList.add('display'); });
        (_b = this.container) === null || _b === void 0 ? void 0 : _b.addEventListener('mouseout', () => { var _a; return (_a = this.node) === null || _a === void 0 ? void 0 : _a.classList.remove('display'); });
        (_c = this.node) === null || _c === void 0 ? void 0 : _c.addEventListener('focus', () => { var _a; return (_a = this.node) === null || _a === void 0 ? void 0 : _a.classList.add('display'); });
        (_d = this.node) === null || _d === void 0 ? void 0 : _d.addEventListener('focusout', () => { var _a; return (_a = this.node) === null || _a === void 0 ? void 0 : _a.classList.remove('display'); });
        document.addEventListener('keydown', (e) => this.handleKeyDown(e));
    }
    handleKeyDown(e) {
        var _a;
        if (e.key === 'Escape') {
            (_a = this.node) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
        }
        ;
    }
}
