import Slider from "./sliderClass";
export default class VolumeSlider extends Slider {
    constructor(slider, accessibility, video, volumeButton) {
        super(slider, accessibility);
        this.language = document.documentElement.getAttribute('lang');
        this.video = video;
        this.volumeButton = volumeButton;
        this.savedValue = 0;
        this.applyEffect = this.applyEffect.bind(this);
        this.handleVolumeSymbol = this.handleVolumeSymbol.bind(this);
    }
    applyEffect() {
        if (this.video && this.value !== undefined)
            this.video.volume = this.value / 100;
        this.savedValue = this.value;
        this.handleVolumeSymbol();
    }
    setAriaValueText() {
        var _a;
        (_a = this.thumb) === null || _a === void 0 ? void 0 : _a.setAttribute('aria-valuetext', `${this.value}%`);
    }
    setValueFromVolumeButton() {
        if (this.value === 0) {
            this.value = this.savedValue;
        }
        else {
            this.value = 0;
        }
        ;
        if (this.video && this.value !== undefined)
            this.video.volume = this.value / 100;
        this.setAriaValues();
        this.handleSliderThumbPosition();
        this.handleVolumeSymbol();
    }
    handleVolumeSymbol() {
        var _a;
        const volumeSymbols = (_a = this.volumeButton) === null || _a === void 0 ? void 0 : _a.querySelectorAll('span');
        let muteSymbol = null;
        let lowVolumeSymbol = null;
        let highVolumeSymbol = null;
        if (volumeSymbols) {
            muteSymbol = volumeSymbols[0];
            lowVolumeSymbol = volumeSymbols[1];
            highVolumeSymbol = volumeSymbols[2];
        }
        ;
        volumeSymbols === null || volumeSymbols === void 0 ? void 0 : volumeSymbols.forEach(symbol => symbol.classList.remove('display'));
        if (this.value !== undefined)
            if (Math.round(this.value) === 0) {
                muteSymbol === null || muteSymbol === void 0 ? void 0 : muteSymbol.classList.add('display');
                // if(this.language === 'fr') {
                //     this.volumeButton?.setAttribute('aria-label','Couper le son');
                // } else {
                //     this.volumeButton?.setAttribute('aria-label','Mute');
                // };
            }
            else if (this.value <= 50) {
                lowVolumeSymbol === null || lowVolumeSymbol === void 0 ? void 0 : lowVolumeSymbol.classList.add('display');
                // if(this.language === 'fr') {
                //     this.volumeButton?.setAttribute('aria-label','Rétablir le volume précédent');
                // } else {
                //     this.volumeButton?.setAttribute('aria-label','Set previous volume level');
                // };
            }
            else {
                highVolumeSymbol === null || highVolumeSymbol === void 0 ? void 0 : highVolumeSymbol.classList.add('display');
                // if(this.language === 'fr') {
                //     this.volumeButton?.setAttribute('aria-label','Rétablir le volume précédent');
                // } else {
                //     this.volumeButton?.setAttribute('aria-label','Set previous volume level');
                // };
            }
        ;
    }
}
