import Form from "./formClass";
export default class ContactForm extends Form {
    constructor(form) {
        super(form);
        this.userMail = document.getElementById('mail');
        this.subject = document.getElementById('subject');
        this.messageContent = document.getElementById('messageContent');
        this.temporaryMessage = document.querySelector('.waiting-message');
        this.applyEffect = this.applyEffect.bind(this);
    }
    applyEffect() {
        var _a, _b, _c, _d;
        //display temporary message
        (_a = this.temporaryMessage) === null || _a === void 0 ? void 0 : _a.classList.add('display');
        // encode fields values for security
        const replaceSpecialChars = (text) => {
            const map = {
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#039;'
            };
            return text === null || text === void 0 ? void 0 : text.replace(/[&<>"']/g, function (m) { return map[m]; });
        };
        let mailData = {
            userMail: replaceSpecialChars((_b = this.userMail) === null || _b === void 0 ? void 0 : _b.value),
            subject: replaceSpecialChars((_c = this.subject) === null || _c === void 0 ? void 0 : _c.value),
            messageContent: replaceSpecialChars((_d = this.messageContent) === null || _d === void 0 ? void 0 : _d.value)
        };
        const options = {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify(mailData)
        };
        fetch('/app/send_mail', options)
            .then(res => {
            var _a;
            (_a = this.temporaryMessage) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
            if (res.ok) {
                this.setSuccessForm();
            }
            else {
                this.setFailForm();
            }
            ;
        })
            .catch(err => {
            var _a;
            (_a = this.temporaryMessage) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
            this.setFailForm();
        });
    }
    ;
}
