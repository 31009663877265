"use strict";
import './mainStyle.scss';
import './mainStyleContrast.scss';
import initialize from './modules/initialize';
import handleTopPage from './modules/handleTopPage';
let initializedObjects = {};
window.addEventListener('DOMContentLoaded', () => {
    initializedObjects = initialize();
    initializedObjects.previews.handleAppear();
});
//------------------- functions ---------------------------------------//
// function visibilityLanguageOption(option) {
//     const isBelow = option.getBoundingClientRect().top + option.clientHeight > window.clientHeight;
//     const isAbove = option.getBoundingClientRect().top < window.scrollY;
//     if(isBelow) {
//         scrollTo(0,option.getBoundingClientRect().top);
//     };
// };
//----------------- Handle events --------------------//
const topPage = document.querySelector('.top-page');
window.addEventListener('mouseup', () => initializedObjects.accessibility.isMouseDown = false);
document.addEventListener('keydown', (e) => {
    var _a;
    switch (e.key) {
        case 's':
            if (e.altKey && e.ctrlKey) {
                if (!((_a = initializedObjects.shortcutsModal.node) === null || _a === void 0 ? void 0 : _a.classList.contains('display'))) {
                    initializedObjects.shortcutsModal.elementToFocusOnClose = document.activeElement;
                    initializedObjects.shortcutsModal.setDisplay();
                }
                ;
            }
            ;
            break;
        default:
            break;
    }
    ;
});
window.addEventListener('scroll', () => {
    initializedObjects.accessibility.handleCharacters();
    if (topPage) {
        handleTopPage(topPage, 300);
    }
    ;
});
const switchContrastMobile = document.querySelector('.switch-contrast-mobile');
switchContrastMobile === null || switchContrastMobile === void 0 ? void 0 : switchContrastMobile.addEventListener('click', () => initializedObjects.accessibility.switchStyle());
const burgerMenuButton = document.querySelector('.menu-container_menu-mobile');
burgerMenuButton === null || burgerMenuButton === void 0 ? void 0 : burgerMenuButton.addEventListener("click", () => {
    initializedObjects.mobileMenuModal.setDisplay();
});
const accessibilityButton = document.querySelector('.accessibility');
topPage === null || topPage === void 0 ? void 0 : topPage.addEventListener('click', function () {
    window.scrollTo(0, 0);
    accessibilityButton === null || accessibilityButton === void 0 ? void 0 : accessibilityButton.focus();
});
//handle quick links display
const quickLinksMenu = document.querySelector('.acces-rapide');
quickLinksMenu === null || quickLinksMenu === void 0 ? void 0 : quickLinksMenu.addEventListener('keydown', (e) => {
    switch (e.key) {
        case 'Escape':
            accessibilityButton === null || accessibilityButton === void 0 ? void 0 : accessibilityButton.focus();
            break;
        default:
            break;
    }
    ;
});
//gère le scroll sur les éléments focusables pour les rendre visible à la prise de focus
// const mainMenuHeight = parseInt(getComputedStyle(mainMenu).height.replace("px",""));
// const mainMenuDebordHeight = parseInt(getComputedStyle(mainMenuDebord).height.replace("px",""));
// const positionsScrollToElements = scrollToElements.map(element => 
//     element.getBoundingClientRect().top - mainMenuHeight - mainMenuDebordHeight - 10);
// scrollToElements.forEach(element => element.addEventListener('focus',(e)=> {
//     let indexScrollToElements = scrollToElements.indexOf(e.target);
//     let positionScrollToElements = positionsScrollToElements[indexScrollToElements];
//     scrollTo(0,positionScrollToElements);
// }));
accessibilityButton === null || accessibilityButton === void 0 ? void 0 : accessibilityButton.addEventListener('click', () => {
    initializedObjects.accessibilityModal.setDisplay();
    //     //gère scroll au focus dans la modale
    //     const modalAccessListPaddingtop = parseInt(getComputedStyle(modalAccessList).paddingTop.replace("px",""));
    //     const modalAccessListTop = modalAccessList.getBoundingClientRect().top - modalAccessListPaddingtop;
    //     const scrollInAccessModal = document.querySelectorAll(".scroll-to");
    //     scrollInAccessModal.forEach(item => item.addEventListener('focusin',(e)=> {
    //         //récupérer le parent avec la classe 'scroll-to'
    //         let currentElement = e.target;
    //         while(!currentElement.classList.contains('scroll-to')) {
    //             currentElement = currentElement.parentElement;
    //         };
    //         //ajuste la position du parent dans la liste 
    //         const itemTop = currentElement.offsetTop-200; //pourquoi 200 fonctionne parfaitement ??
    //         modalAccessList.scrollTo(0,itemTop);
});
const shortcutsButton = document.querySelector('.shortcuts-button');
shortcutsButton === null || shortcutsButton === void 0 ? void 0 : shortcutsButton.addEventListener('click', () => {
    initializedObjects.shortcutsModal.elementToFocusOnClose = document.activeElement;
    initializedObjects.shortcutsModal.setDisplay();
});
const picturesButton = document.querySelectorAll('.picture-button');
picturesButton.forEach(picture => picture.addEventListener('contextmenu', (e) => {
    e.preventDefault();
}));
//accordeon buttons in main
const accordeonButtons = document.querySelectorAll('main .accordeon-button');
accordeonButtons.forEach(button => button.addEventListener('click', () => {
    var _a, _b, _c, _d;
    if (button.getAttribute('aria-expanded') === 'true') {
        button.setAttribute('aria-expanded', 'false');
        (_b = (_a = button.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.classList.remove('display');
    }
    else {
        accordeonButtons.forEach(button => {
            var _a, _b;
            button.setAttribute('aria-expanded', 'false');
            (_b = (_a = button.parentElement) === null || _a === void 0 ? void 0 : _a.parentElement) === null || _b === void 0 ? void 0 : _b.classList.remove('display');
        });
        button.setAttribute('aria-expanded', 'true');
        (_d = (_c = button.parentElement) === null || _c === void 0 ? void 0 : _c.parentElement) === null || _d === void 0 ? void 0 : _d.classList.add('display');
    }
    ;
}));
//accordeon buttons in shortcuts modal
const shortcutsAccordeonButtons = document.querySelectorAll('.modal-shortcuts .accordeon-button');
shortcutsAccordeonButtons.forEach(button => button.addEventListener('click', () => {
    var _a, _b, _c, _d;
    if (button.getAttribute('aria-expanded') === 'true') {
        button.setAttribute('aria-expanded', 'false');
        (_b = (_a = button.parentElement) === null || _a === void 0 ? void 0 : _a.nextElementSibling) === null || _b === void 0 ? void 0 : _b.classList.remove('display');
    }
    else {
        shortcutsAccordeonButtons.forEach(button => {
            var _a, _b;
            button.setAttribute('aria-expanded', 'false');
            (_b = (_a = button.parentElement) === null || _a === void 0 ? void 0 : _a.nextElementSibling) === null || _b === void 0 ? void 0 : _b.classList.remove('display');
        });
        button.setAttribute('aria-expanded', 'true');
        (_d = (_c = button.parentElement) === null || _c === void 0 ? void 0 : _c.nextElementSibling) === null || _d === void 0 ? void 0 : _d.classList.add('display');
    }
    ;
}));
const detailledDescriptions = document.querySelectorAll('.picture-description_text');
const videoTranscriptions = document.querySelectorAll('.video-transcription_text');
window.addEventListener('click', (e) => {
    var _a, _b;
    if (!((_a = initializedObjects.languageSelect.node) === null || _a === void 0 ? void 0 : _a.contains(e.target))) {
        initializedObjects.languageSelect.reduce();
    }
    ;
    if (!((_b = initializedObjects.mainMenu.node) === null || _b === void 0 ? void 0 : _b.contains(e.target))) {
        initializedObjects.mainMenu.subMenus.forEach(submenu => {
            var _a;
            if (submenu.isExpanded) {
                initializedObjects.mainMenu.closeSubMenu(submenu);
                (_a = submenu.controlLink) === null || _a === void 0 ? void 0 : _a.focus();
            }
            ;
        });
    }
    ;
    detailledDescriptions.forEach(description => {
        var _a, _b;
        if (!((_a = description.parentElement) === null || _a === void 0 ? void 0 : _a.contains(e.target))) {
            description.classList.remove('display');
            (_b = description.previousElementSibling) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-expanded', 'false');
        }
        ;
    });
    videoTranscriptions.forEach(description => {
        var _a, _b;
        if (!description.contains(e.target) && !((_a = description.previousElementSibling) === null || _a === void 0 ? void 0 : _a.contains(e.target))) {
            description.classList.remove('display');
            (_b = description.previousElementSibling) === null || _b === void 0 ? void 0 : _b.setAttribute('aria-expanded', 'false');
        }
        ;
    });
});
const detailledDescriptionButtons = document.querySelectorAll('.picture-description_title');
detailledDescriptionButtons.forEach(button => button.addEventListener('click', () => {
    var _a, _b;
    if (button.getAttribute('aria-expanded') === 'true') {
        button.setAttribute('aria-expanded', 'false');
        (_a = button.nextElementSibling) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
    }
    else {
        button.setAttribute('aria-expanded', 'true');
        (_b = button.nextElementSibling) === null || _b === void 0 ? void 0 : _b.classList.add('display');
    }
    ;
}));
const videoTranscriptionButtons = document.querySelectorAll('.video-transcription_title');
videoTranscriptionButtons.forEach(button => button.addEventListener('click', () => {
    var _a, _b;
    if (button.getAttribute('aria-expanded') === 'true') {
        button.setAttribute('aria-expanded', 'false');
        (_a = button.nextElementSibling) === null || _a === void 0 ? void 0 : _a.classList.remove('display');
    }
    else {
        button.setAttribute('aria-expanded', 'true');
        (_b = button.nextElementSibling) === null || _b === void 0 ? void 0 : _b.classList.add('display');
    }
    ;
}));
